import { ENFT_GRADE } from './nft';
import { IUser } from './user';

export enum ENFTStakingInvalidatedReason {
  STAKING_STARTED_BY_ANOTHER_USER = 'STAKING_STARTED_BY_ANOTHER_USER',
  USER_DISCONNECTED_HOT_WALLET = 'USER_DISCONNECTED_HOT_WALLET',
}

export interface IFarming {
  id: string;
  user: IUser;
  userId: string;
  startTime: Date;
  endTime: Date;
  isClaimed: boolean;
  isActive: boolean;
  createdAt: Date;
  updatedAt: Date;
  speedBoostLevel: number;
  timeBoostLevel: number;
}

export interface INFTStaking {
  id: string;
  user?: IUser;
  userId: string;
  nftId: string;
  nftGrade: ENFT_GRADE;
  invalidatedReason: ENFTStakingInvalidatedReason;
  invalidated: boolean;
  startTime: Date;
  endTime: Date;
  isClaimed: boolean;
  isActive: boolean;
  createdAt: Date;
  updatedAt: Date;
  coinsPerHour: number;
  ticketsPerHour: number;
}
