import React, { memo, useEffect } from 'react';
import { useLocation, useOutlet } from 'react-router-dom';
import { AnimatePresence } from 'framer-motion';
import Footer from 'components/Footer';
import { EModals } from 'interface/modals';
import useModalStore from 'store/modals.store';
import ScrollToTop from 'components/ScrollToTop';
import useBalanceStore from 'store/balance.store';
import { IDailyReward } from 'interface/user';
import { useNearWallet } from 'hooks/web3/useNearWallet';
import { getGifts } from 'api/gits';

import './Main.scss';

interface IMainProps {
  isUser: boolean;
  isGifts: boolean;
  dailyReward?: IDailyReward | null;
}

const AnimatedOutlet = (): React.JSX.Element => {
  const location = useLocation();
  const element = useOutlet();

  return (
    <AnimatePresence mode="wait" initial={true}>
      {element && React.cloneElement(element, { key: location.pathname })}
    </AnimatePresence>
  );
};

const Main: React.FC<IMainProps> = ({ isUser, isGifts, dailyReward }) => {
  const addCoins = useBalanceStore((state) => state.addCoins);
  const addTickets = useBalanceStore((state) => state.addTickets);
  const showModal = useModalStore((state) => state.showModal);

  useEffect(() => {
    if (dailyReward && dailyReward?.loginStreak >= 1 && dailyReward.isNewDay) {
      showModal(EModals.DAILY_REWARD, { dailyReward });
      addCoins(dailyReward.coins);
      addTickets(dailyReward.tickets);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dailyReward?.loginStreak, dailyReward?.isNewDay]);

  useEffect(() => {
    if (isGifts) {
      getGifts().then((gifts) =>
        gifts.forEach((gift) =>
          showModal(EModals.GIFT, {
            id: gift.id,
            coins: gift.coins,
            tickets: gift.tickets,
            description: gift.description,
          }),
        ),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isGifts]);

  useNearWallet();

  return (
    <div className="container">
      <ScrollToTop />
      <AnimatedOutlet />
      {isUser && <Footer />}
    </div>
  );
};

export default memo(Main);
