import React from 'react';
import { NavLink } from 'react-router-dom';

import { ReactComponent as HomeIcon } from 'assets/images/footer/home.svg';
import { ReactComponent as StatsIcon } from 'assets/images/footer/stats.svg';
import { ReactComponent as HodlIcon } from 'assets/images/footer/nft.svg';
import { ReactComponent as TasksIcon } from 'assets/images/footer/tasks.svg';
import { ReactComponent as ReferralsIcon } from 'assets/images/footer/referrals.svg';

import './Footer.scss';

const Footer: React.FC = () => {
  return (
    <footer>
      <ul>
        <li>
          <NavLink to="/" className={({ isActive }) => (isActive ? 'active' : '')}>
            <HomeIcon /> Home
          </NavLink>
        </li>
        <li>
          <NavLink to="/stats" className={({ isActive }) => (isActive ? 'active' : '')}>
            <StatsIcon /> Stats
          </NavLink>
        </li>
        <li className="nft-link">
          <NavLink to="/nft" className={({ isActive }) => (isActive ? 'active' : '')}>
            <HodlIcon /> Hodl
          </NavLink>
        </li>
        <li>
          <NavLink to="/tasks" className={({ isActive }) => (isActive ? 'active' : '')}>
            <TasksIcon /> Tasks
          </NavLink>
        </li>
        <li>
          <NavLink to="/referrals" className={({ isActive }) => (isActive ? 'active' : '')}>
            <ReferralsIcon /> Referrals
          </NavLink>
        </li>
      </ul>
    </footer>
  );
};

export default Footer;
