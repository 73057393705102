import Button from 'components/Button';
import {
  NFTBuyLinkHERE,
  NFTBuyLinkYUP,
  NFTBuyLinkUSMen,
  NFTBuyLinkPT,
  NFTBuyLinkDD,
  NFTBuyLinkHAPI,
} from 'constants/nft';
import { motion } from 'framer-motion';
import { ENFT_TYPE } from 'interface/nft';
import { defaultAnimation } from 'styles/animations';

const NoNFTsMessage: React.FC<{ type: ENFT_TYPE }> = ({ type }) => {
  const openNFTLink = () => {
    const link = {
      [ENFT_TYPE.HOT]: NFTBuyLinkHERE,
      [ENFT_TYPE.PITCHTALK]: NFTBuyLinkPT,
      [ENFT_TYPE.HAPI]: NFTBuyLinkHAPI,
      [ENFT_TYPE.DD]: NFTBuyLinkDD,
      [ENFT_TYPE.USMEN]: NFTBuyLinkUSMen,
      [ENFT_TYPE.YUP]: NFTBuyLinkYUP,
    };

    window.open(link[type], '_blank', 'noreferrer noopener');
  };

  return (
    <motion.div className="nft-page-body-content-list no-nfts-message" {...defaultAnimation}>
      <ul>
        <li className="nft-page-body-content-list-item">
          <div style={{ width: '160px', height: '160px' }} />
        </li>
        <li className="nft-page-body-content-list-item">
          <div style={{ width: '160px', height: '160px' }} />
        </li>
        <li className="nft-page-body-content-list-item">
          <div style={{ width: '160px', height: '160px' }} />
        </li>
      </ul>
      <div className="no-nfts-message-button-container">
        <p>No items found</p>
        <Button
          className="buy-nft-button"
          color="grey"
          onClick={openNFTLink}
          text={
            <>
              Start collecting!
              <img src="images/arrow.svg" alt="Arrow" style={{ width: '16px', height: '16px' }} />
            </>
          }
        />
      </div>
    </motion.div>
  );
};

export default NoNFTsMessage;
