import React, { useEffect, useRef } from 'react';
import { motion } from 'framer-motion';
import classNames from 'classnames';
import { LEVELS } from 'constants/levels';
import Button from 'components/Button';
import useMainStore from 'store/main.store';
import { defaultAnimation } from 'styles/animations';
import useModalStore from 'store/modals.store';
import { EModals } from 'interface/modals';
import withComingSoon from 'components/CommingSoon';

import './LevelsPage.scss';

const LevelsPage: React.FC = () => {
  const currentLevel = useMainStore((state) => state.user?.level || 1);
  const { showModal } = useModalStore();
  const currentLevelRef = useRef<HTMLLIElement>(null);

  const openUpgradeModal = () => {
    showModal(EModals.LEVEL_UPGRADE, { nextLevel: currentLevel + 1 });
  };

  const maxLevel = 3;
  const filteredLevels = Object.keys(LEVELS)
    .filter((level) => LEVELS[level as unknown as keyof typeof LEVELS].level <= maxLevel + 1)
    .reduce((obj, key) => {
      obj[key as unknown as keyof typeof LEVELS] = LEVELS[key as unknown as keyof typeof LEVELS];
      return obj;
    }, {} as typeof LEVELS);

  useEffect(() => {
    setTimeout(() => {
      if (currentLevelRef.current) {
        currentLevelRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
    }, 300);
  }, []);

  return (
    <motion.div className="levels-page" {...defaultAnimation}>
      <div className="levels-page-header">
        <h4>Level up to get bonuses</h4>
      </div>
      <ul className="levels-page-body">
        {Object.values(filteredLevels).map((level, i) =>
          i === maxLevel ? (
            withComingSoon(() => (
              <li className={classNames('level-item')} key={level.level}>
                <p className="level-item__level">Level {i + 1}</p>
                <img src={level.levelIcon} alt={level.name} />
                <h3 className="level-item__name">{level.name}</h3>
                {level.level !== 1 && (
                  <p className="level-item__bonus">
                    <span>$PTCH farming multiplier</span>
                    <b>{level.level === 10 ? 'x???' : `x${level.bonus}`}</b>
                  </p>
                )}
              </li>
            ))({ key: level.level })
          ) : (
            <li
              className={classNames('level-item', {
                'level-item--current': level.level === currentLevel,
              })}
              ref={level.level === currentLevel ? currentLevelRef : null}
              key={level.level}
              style={{
                border: level.level === currentLevel ? `4px solid ${level.color}` : 'none',
              }}>
              <p className="level-item__level">Level {i + 1}</p>
              <img src={level.levelIcon} alt={level.name} />
              <h3 className="level-item__name">{level.name}</h3>
              {level.level !== 1 && (
                <p className="level-item__bonus">
                  <span>$PTCH farming multiplier</span>
                  <b>{level.level === 10 ? 'x???' : `x${level.bonus}`}</b>
                </p>
              )}
              {level.level === currentLevel + 1 && (
                <Button color="white" text="Level Up" onClick={openUpgradeModal} />
              )}
            </li>
          ),
        )}
      </ul>
    </motion.div>
  );
};

export default LevelsPage;
