import { create } from 'zustand';
import {
  ENFT_DD_GRADE,
  ENFT_GRADE,
  ENFT_USMEN_GRADE,
  ENFT_YUP_GRADE,
  INFTToken,
} from 'interface/nft';

interface INFTsStore {
  nfts: Map<string, INFTToken>;
  grades: Map<string, ENFT_GRADE>;
  bonuses: Map<string, boolean>;

  hereNfts: Map<string, INFTToken>;
  hereGrades: Map<string, ENFT_GRADE>;
  hereBonuses: Map<string, boolean>;

  hapiNfts: Map<string, INFTToken>;
  hapiBonuses: Map<string, boolean>;

  usmenNfts: Map<string, INFTToken>;
  usmenGrades: Map<string, ENFT_USMEN_GRADE>;
  usmenBonuses: Map<string, boolean>;

  ddNfts: Map<string, INFTToken>;
  ddGrades: Map<string, ENFT_DD_GRADE>;
  ddBonuses: Map<string, boolean>;

  yupNfts: Map<string, INFTToken>;
  yupGrades: Map<string, ENFT_YUP_GRADE>;
  yupBonuses: Map<string, boolean>;

  isLoadingNFT: boolean;

  setNFTs: (nfts: INFTToken[]) => void;
  setGrades: (levels: Map<string, ENFT_GRADE>) => void;
  setBonus: (tokenId: string, bonus: boolean) => void;
  setBonuses: (bonuses: Map<string, boolean>) => void;

  setHereNFTs: (nfts: INFTToken[]) => void;
  setHereGrades: (levels: Map<string, ENFT_GRADE>) => void;
  setHereBonus: (tokenId: string, bonus: boolean) => void;
  setHereBonuses: (bonuses: Map<string, boolean>) => void;

  setHapiNFTs: (nfts: INFTToken[]) => void;
  setHapiBonus: (tokenId: string, bonus: boolean) => void;
  setHapiBonuses: (bonuses: Map<string, boolean>) => void;

  setUsmenNFTs: (nfts: INFTToken[]) => void;
  setUsmenGrades: (grades: Map<string, ENFT_USMEN_GRADE>) => void;
  setUsmenBonus: (tokenId: string, bonus: boolean) => void;
  setUsmenBonuses: (bonuses: Map<string, boolean>) => void;

  setDDNFTs: (nfts: INFTToken[]) => void;
  setDDGrades: (grades: Map<string, ENFT_DD_GRADE>) => void;
  setDDBonus: (tokenId: string, bonus: boolean) => void;
  setDDBonuses: (bonuses: Map<string, boolean>) => void;

  setYupNFTs: (nfts: INFTToken[]) => void;
  setYupGrades: (grades: Map<string, ENFT_YUP_GRADE>) => void;
  setYupBonus: (tokenId: string, bonus: boolean) => void;
  setYupBonuses: (bonuses: Map<string, boolean>) => void;

  setIsLoadingNFT: (isLoading: boolean) => void;

  getGradeById: (tokenId: string) => ENFT_GRADE;
  getUsmenGradeById: (tokenId: string) => ENFT_USMEN_GRADE;
  getDDGradeById: (tokenId: string) => ENFT_DD_GRADE;
  getYupGradeById: (tokenId: string) => ENFT_YUP_GRADE;
}

const useNFTsStore = create<INFTsStore>((set, get) => ({
  nfts: new Map(),
  grades: new Map(),
  bonuses: new Map(),

  hereNfts: new Map(),
  hereGrades: new Map(),
  hereBonuses: new Map(),

  hapiNfts: new Map(),
  hapiGrades: new Map(),
  hapiBonuses: new Map(),

  usmenNfts: new Map(),
  usmenGrades: new Map(),
  usmenBonuses: new Map(),

  ddNfts: new Map(),
  ddGrades: new Map(),
  ddBonuses: new Map(),

  yupNfts: new Map(),
  yupGrades: new Map(),
  yupBonuses: new Map(),

  isLoadingNFT: false,

  setNFTs: (nfts: INFTToken[]) => set({ nfts: new Map(nfts.map((nft) => [nft.token_id, nft])) }),
  setGrades: (grades: Map<string, ENFT_GRADE>) => set({ grades: new Map(grades) }),
  setBonus: (tokenId: string, bonus: boolean) =>
    set((state) => ({
      ...state,
      bonuses: new Map(state.bonuses).set(tokenId, bonus),
    })),
  setBonuses: (bonuses: Map<string, boolean>) => set({ bonuses: new Map(bonuses) }),

  setHereNFTs: (nfts: INFTToken[]) =>
    set({ hereNfts: new Map(nfts.map((nft) => [nft.token_id, nft])) }),
  setHereGrades: (grades: Map<string, ENFT_GRADE>) => set({ hereGrades: new Map(grades) }),
  setHereBonus: (tokenId: string, bonus: boolean) =>
    set((state) => ({
      ...state,
      hereBonuses: new Map(state.hereBonuses).set(tokenId, bonus),
    })),
  setHereBonuses: (bonuses: Map<string, boolean>) => set({ hereBonuses: new Map(bonuses) }),

  setHapiNFTs: (nfts: INFTToken[]) =>
    set({ hapiNfts: new Map(nfts.map((nft) => [nft.token_id, nft])) }),
  setHapiBonus: (tokenId: string, bonus: boolean) =>
    set((state) => ({
      ...state,
      hapiBonuses: new Map(state.hapiBonuses).set(tokenId, bonus),
    })),
  setHapiBonuses: (bonuses: Map<string, boolean>) => set({ hapiBonuses: new Map(bonuses) }),

  setUsmenNFTs: (nfts: INFTToken[]) =>
    set({ usmenNfts: new Map(nfts.map((nft) => [nft.token_id, nft])) }),
  setUsmenGrades: (grades: Map<string, ENFT_USMEN_GRADE>) => set({ usmenGrades: new Map(grades) }),
  setUsmenBonus: (tokenId: string, bonus: boolean) =>
    set((state) => ({
      ...state,
      usmenBonuses: new Map(state.usmenBonuses).set(tokenId, bonus),
    })),
  setUsmenBonuses: (bonuses: Map<string, boolean>) => set({ usmenBonuses: new Map(bonuses) }),

  setDDNFTs: (nfts: INFTToken[]) =>
    set({ ddNfts: new Map(nfts.map((nft) => [nft.token_id, nft])) }),
  setDDGrades: (grades: Map<string, ENFT_DD_GRADE>) => set({ ddGrades: new Map(grades) }),
  setDDBonus: (tokenId: string, bonus: boolean) =>
    set((state) => ({
      ...state,
      ddBonuses: new Map(state.ddBonuses).set(tokenId, bonus),
    })),
  setDDBonuses: (bonuses: Map<string, boolean>) => set({ ddBonuses: new Map(bonuses) }),

  setYupNFTs: (nfts: INFTToken[]) =>
    set({ yupNfts: new Map(nfts.map((nft) => [nft.token_id, nft])) }),
  setYupGrades: (grades: Map<string, ENFT_YUP_GRADE>) => set({ yupGrades: new Map(grades) }),
  setYupBonus: (tokenId: string, bonus: boolean) =>
    set((state) => ({
      ...state,
      yupBonuses: new Map(state.yupBonuses).set(tokenId, bonus),
    })),
  setYupBonuses: (bonuses: Map<string, boolean>) => set({ yupBonuses: new Map(bonuses) }),

  setIsLoadingNFT: (isLoading: boolean) => set(() => ({ isLoadingNFT: isLoading })),

  getGradeById: (tokenId: string) => get().grades.get(tokenId) as ENFT_GRADE,
  getUsmenGradeById: (tokenId: string) => get().usmenGrades.get(tokenId) as ENFT_USMEN_GRADE,
  getDDGradeById: (tokenId: string) => get().ddGrades.get(tokenId) as ENFT_DD_GRADE,
  getYupGradeById: (tokenId: string) => get().yupGrades.get(tokenId) as ENFT_YUP_GRADE,
}));

export default useNFTsStore;
