import React from 'react';

import './LoadingScreen.scss';

const LoadingScreen: React.FC = () => (
  <div className="loading-screen">
    <img className="loading-img" src="/images/loading.png" alt="Loading" />
    <img className="shadow" src="/images/shadow.png" alt="" />
  </div>
);

export default LoadingScreen;
