export const LEVELS = {
  1: {
    level: 1,
    levelImage: 'images/level/1.svg',
    levelIcon: 'images/level/icon-1.png',
    name: 'Newbie',
    color: '#F38BAF',
    bonus: 1,
    coins: 0,
    tickets: 0,
    referrals: 0,
    hapiScore: 0,
  },
  2: {
    level: 2,
    levelImage: 'images/level/2.svg',
    levelIcon: 'images/level/icon-2.png',
    name: 'Moon Trader',
    color: '#68C3D4',
    bonus: 1.2,
    coins: 50_000,
    tickets: 25,
    referrals: 2,
    hapiScore: 0,
  },
  3: {
    level: 3,
    levelImage: 'images/level/3.svg',
    levelIcon: 'images/level/icon-3.png',
    name: 'HODLer',
    color: '#FF9A4F',
    bonus: 1.4,
    coins: 125_000,
    tickets: 50,
    referrals: 5,
    hapiScore: 0,
  },
  4: {
    level: 4,
    levelImage: 'images/level/4.svg',
    levelIcon: 'images/level/icon-4.png',
    name: 'Yield Farmer',
    color: '#F4E76E',
    bonus: 1.9,
    coins: 250_000,
    tickets: 60,
    referrals: 8,
    hapiScore: 1,
  },
  5: {
    level: 5,
    levelImage: 'images/level/5.svg',
    levelIcon: 'images/level/icon-5.png',
    name: 'Token Flipper',
    color: '#B2E556',
    bonus: 2.4,
    coins: 625_000,
    tickets: 234,
    referrals: 40,
    hapiScore: 1,
  },
  6: {
    level: 6,
    levelImage: 'images/level/6.svg',
    levelIcon: 'images/level/icon-6.png',
    name: 'ICO Insider',
    color: '#5EB27B',
    bonus: 3.2,
    coins: 1_562_500,
    tickets: 586,
    referrals: 60,
    hapiScore: 1,
  },
  7: {
    level: 7,
    levelImage: 'images/level/7.svg',
    levelIcon: 'images/level/icon-7.png',
    name: 'Blockchain Guru',
    color: '#F9E6FF',
    bonus: 4.4,
    coins: 3_906_250,
    tickets: 1_465,
    referrals: 90,
    hapiScore: 1,
  },
  8: {
    level: 8,
    levelImage: 'images/level/8.svg',
    levelIcon: 'images/level/icon-8.png',
    name: 'Unicorn Hunter',
    color: '#B588EF',
    bonus: 6.2,
    coins: 9_765_625,
    tickets: 3_662,
    referrals: 135,
    hapiScore: 1,
  },
  9: {
    level: 9,
    levelImage: 'images/level/9.svg',
    levelIcon: 'images/level/icon-9.png',
    name: 'Shark',
    color: '#77A0C6',
    bonus: 8.7,
    coins: 24_414_063,
    tickets: 9_155,
    referrals: 203,
    hapiScore: 1,
  },
  10: {
    level: 10,
    levelImage: 'images/level/10.svg',
    levelIcon: 'images/level/icon-10.png',
    name: 'Whale',
    color: '#F8C40F',
    bonus: 10,
    coins: 61_035_156,
    tickets: 22_888,
    referrals: 304,
    hapiScore: 1,
  },
};
