import api from './APIService';
import { IUser } from 'interface/user';
import { handleApiError } from './errorHandlerService';
import HEADERS from 'constants/headers';

export const fetchLeaders = async (
  page: number,
  limit: number,
): Promise<{ leaders: IUser[]; totalRecords: number }> => {
  try {
    const response = await api.get<IUser[]>(`/users?page=${page}&limit=${limit}`);
    const leaders = response.data.sort((a, b) => b.coins - a.coins);

    // @ts-ignore
    const totalRecords = Number(response.headers.get(HEADERS.TOTAL_RECORDS) || 0);

    return { leaders, totalRecords };
  } catch (error: any) {
    handleApiError(error);
    return { leaders: [], totalRecords: 0 };
  }
};

export const fetchUserPosition = async (): Promise<number> => {
  const response = await api.get<{ rank: string }>('/users/rank');
  return Number(response.data.rank || 0);
};
