import { useEffect, useState } from 'react';
import { ToastContainer } from 'react-toastify';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import ReactGA from 'react-ga4';
import Modals from 'providers/ModalsProvider';
import Main from 'components/Main';
import LoadingScreen from 'components/LoadingScreen';
import { preloadFonts, preloadImages } from 'utils/preload';
import { loginUser } from 'api/user';
import { getFarming, getStaking } from 'api/farming';
import { getTasks } from 'api/tasks';
import useMainStore from 'store/main.store';
import useBalanceStore from 'store/balance.store';
import useFarmingStore from 'store/farming.store';
import { showErrorToast } from 'utils/toastUtils';
import { setItem } from 'utils/storage';
import { IDailyReward } from 'interface/user';
import { ETaskSlug, ETaskType } from 'interface/task';
import Home from 'pages/home';
import Stats from 'pages/stats';
import Referrals from 'pages/referrals';
import Template from 'pages/template';
import Levels from 'pages/levels';
import Tasks from 'pages/tasks';
import NFTPage from 'pages/nft';
import AdminPanel from 'pages/admin/panel';
import AdminDaily from 'pages/admin/daily';
import AdminGift from 'pages/admin/gift';
import useTasksStore from 'store/tasks.store';
import { currentEnvironment, EEnvironment, GA } from 'config';
import Maintenance from 'components/Maintenance';
import { WebApp } from 'global';

import '@near-wallet-selector/modal-ui/styles.css';
import 'react-toastify/dist/ReactToastify.css';
import './styles/global.scss';

export const isMock = currentEnvironment === EEnvironment.MAINNET ? false : false;
const mock = '';

const getUserMockData = () => {
  const params = new URLSearchParams(mock);
  const referralCode = params.get('start_param') || '';
  const user = JSON.parse(params.get('user') || '');
  const telegramId = user.id.toString();
  const username = user.username;
  const hash = params.get('hash') || '';
  const photoUrl = params.toString();
  return { referralCode, telegramId, username, hash, photoUrl };
};

const getUserData = (tg: WebApp) => {
  const params = new URLSearchParams(tg.initData);
  const referralCode = params.get('start_param') || '';
  const telegramId = (tg.initDataUnsafe.user?.id || '').toString();
  const username = (tg.initDataUnsafe.user?.username || '').toString();
  const photoUrl = (tg.initDataUnsafe.user?.photo_url || '').toString();
  const hash = tg.initData;
  return { referralCode, telegramId, username, hash, photoUrl };
};

const App: React.FC = () => {
  const setTG = useMainStore((state) => state.setTG);
  const setUser = useMainStore((state) => state.setUser);
  const updateCoins = useBalanceStore((state) => state.updateCoins);
  const updateTickets = useBalanceStore((state) => state.updateTickets);
  const updateReferralRewards = useBalanceStore((state) => state.updateReferralRewards);
  const setFarming = useFarmingStore((state) => state.setFarming);
  const setStaking = useFarmingStore((state) => state.setStaking);
  const setSpeedBoostLevel = useFarmingStore((state) => state.setSpeedBoostLevel);
  const setTimeBoostLevel = useFarmingStore((state) => state.setTimeBoostLevel);
  const setIsLoadingFarming = useFarmingStore((state) => state.setIsLoading);
  const updatePartnerTaskStatus = useTasksStore((state) => state.updatePartnerTaskStatus);
  const updateTask = useTasksStore((state) => state.updateTask);
  const setGeneralTasks = useTasksStore((state) => state.setGeneralTasks);
  const [isLoadedApp, setIsLoadedApp] = useState(false);
  const [user, setIsUser] = useState(false);
  const [isGifts, setIsGifts] = useState(false);
  const [dailyReward, setDailyReward] = useState<IDailyReward | null>(null);

  const isMaintenance = false;

  useEffect(() => {
    (async () => {
      const tg = window.Telegram.WebApp;
      tg.ready();
      tg.expand();

      const color = '#090E15';
      tg.setHeaderColor(color);
      tg.setBottomBarColor(color);
      tg.setBackgroundColor(color);

      await preloadFonts();
      if (isMaintenance) return;

      if (currentEnvironment === EEnvironment.MAINNET) {
        ReactGA.initialize(GA);
        ReactGA.send('pageview');
      }

      await preloadImages();

      setTG(tg);
      setIsLoadedApp(true);

      const { referralCode, telegramId, username, hash, photoUrl } = isMock
        ? getUserMockData()
        : getUserData(tg);

      if (!telegramId) {
        showErrorToast('Cannot find telegram id!');
        return;
      }
      if (!username) {
        showErrorToast('Please set up your username!');
        return;
      }

      setItem('hash', hash);
      const userData = await loginUser(telegramId, username, hash, referralCode, photoUrl);
      if (!userData) {
        console.log('Cannot login user');
        return;
      }

      setItem('token', userData.accessToken);
      setUser(userData.user);

      updateTickets(userData.user.tickets);
      updateCoins(userData.user.coins);
      updateReferralRewards(userData.user.referralRewards);

      const dailyReward = userData.dailyRewards;
      setDailyReward(dailyReward);

      if (userData.user.farmingId) {
        const farming = await getFarming();
        setFarming(farming);
      }
      if (userData.user.nftStakingId) {
        const staking = await getStaking();
        setStaking(staking);
      }
      setIsLoadingFarming(false);

      const tasks = await getTasks();
      const basicTasks = tasks.filter((task) => task.template.type === ETaskType.BASIC);
      const partnersTasks = tasks.filter((task) => task.template.type === ETaskType.PARTNER);
      setGeneralTasks(basicTasks);
      partnersTasks.forEach((task) => {
        updatePartnerTaskStatus(task.template.slug, task.status);
        if (task.template.slug === ETaskSlug.PARTNER_FITTON) {
          updateTask(task.template.slug, { startedAt: task.startedAt });
        }
      });

      ReactGA.event({
        category: 'User',
        action: 'Active Session',
        label: 'Telegram Mini App',
      });

      setIsGifts(userData.user.isNewGifts);

      setSpeedBoostLevel(userData.user?.speedBoostLevel || 1);
      setTimeBoostLevel(userData.user?.timeBoostLevel || 1);

      setIsUser(!!userData.user.id);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {isMaintenance ? (
        <Maintenance />
      ) : (
        <>
          <Router>
            <Routes>
              <Route
                path="/"
                element={
                  isLoadedApp ? (
                    <Main isUser={user} isGifts={isGifts} dailyReward={dailyReward} />
                  ) : (
                    <LoadingScreen />
                  )
                }>
                <Route index element={user ? <Home /> : <LoadingScreen />} />
                <Route path="/stats" element={user ? <Stats /> : <LoadingScreen />} />
                <Route path="/tasks" element={user ? <Tasks /> : <LoadingScreen />} />
                <Route path="/referrals" element={user ? <Referrals /> : <LoadingScreen />} />
                <Route path="/nft" element={user ? <NFTPage /> : <LoadingScreen />} />
                <Route path="/template" element={user ? <Template /> : <LoadingScreen />} />
                <Route path="/levels" element={user ? <Levels /> : <LoadingScreen />} />
                <Route path="/admin" element={<AdminPanel />} />
                <Route path="/admin-daily" element={<AdminDaily />} />
                <Route path="/admin-gift" element={<AdminGift />} />
                <Route path="/*" element={<LoadingScreen />} />
              </Route>
            </Routes>
          </Router>
          <Modals />
          <ToastContainer position="top-center" hideProgressBar />
        </>
      )}
    </>
  );
};

export default App;
