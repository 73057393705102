import { create } from 'zustand';
import { Wallet } from '@near-wallet-selector/core';
import { WalletSelectorModal } from '@near-wallet-selector/modal-ui';
import { WalletSelector } from '@near-wallet-selector/core';

interface IWeb3Store {
  nearWallet: (Wallet & { accountId: string }) | null;
  setNearWallet: (wallet: (Wallet & { accountId: string }) | null) => void;

  selector: WalletSelector | null;
  modal: WalletSelectorModal | null;
  setSelector: (selector: WalletSelector | null) => void;
  setModal: (modal: WalletSelectorModal | null) => void;
}

const useWeb3Store = create<IWeb3Store>((set) => ({
  nearWallet: null,
  setNearWallet: (wallet: (Wallet & { accountId: string }) | null) =>
    set((state) => ({ ...state, nearWallet: wallet })),

  selector: null,
  modal: null,
  setSelector: (selector) => set({ selector }),
  setModal: (modal) => set({ modal }),
}));

export default useWeb3Store;
