import { providers } from 'near-api-js';
import { IRPCProviderService, RPCProviderService } from './RPCProvider';
import { ENFT_TYPE, INFTTokens } from 'interface/nft';
import {
  ddContractId,
  hapiContractId,
  hapiScoreContractId,
  hereContractId,
  nftContractId,
  usmenContractId,
  yupContractId,
} from 'config';
import { NFTS_ACTIVE } from 'constants/nft';

class NearService {
  private static provider: IRPCProviderService;
  private static hapiProvider: string = 'https://near-rpc.hapilabs.one';
  private static mainnetProvider: string = 'https://rpc.mainnet.near.org';

  public static initialize() {
    const provider = new providers.JsonRpcProvider({ url: this.mainnetProvider });
    this.provider = new RPCProviderService(provider);
  }

  static async getUserWeb3NFTs(account_id: string, nftType: ENFT_TYPE): Promise<INFTTokens> {
    if (!NFTS_ACTIVE[nftType]) {
      return [];
    }
    const contractId = {
      [ENFT_TYPE.PITCHTALK]: nftContractId,
      [ENFT_TYPE.HOT]: hereContractId,
      [ENFT_TYPE.HAPI]: hapiContractId,
      [ENFT_TYPE.USMEN]: usmenContractId,
      [ENFT_TYPE.DD]: ddContractId,
      [ENFT_TYPE.YUP]: yupContractId,
    };

    // const accountId = {
    //   [ENFT_TYPE.PITCHTALK]: account_id,
    //   [ENFT_TYPE.HOT]: 'proakro.near',
    //   [ENFT_TYPE.HAPI]: 'e54c0b87de151de608e0cfb9f31c2a70001219b9fd6a85be36d1089c95af851b',
    //   [ENFT_TYPE.DD]: 'e54c0b87de151de608e0cfb9f31c2a70001219b9fd6a85be36d1089c95af851b',
    // };

    try {
      const nfts = await NearService.provider.viewFunction<{ account_id: string }, INFTTokens>(
        'nft_tokens_for_owner',
        contractId[nftType],
        { account_id },
      );

      return nfts || [];
    } catch (error) {
      console.error('Error fetching NFTs:', error);
      return [];
    }
  }

  static async getHapiScore(accountId: string): Promise<number> {
    try {
      if (!accountId) return 0;
      const result = await NearService.provider.viewFunction<
        { account_id: string },
        { trust_score_record: { score: number } }
      >('get_user', hapiScoreContractId, { account_id: accountId });
      const nearTrustScore = result?.trust_score_record?.score;
      return nearTrustScore;
    } catch (error) {
      console.error('Error fetching hapi score:', error);
      throw error;
    }
  }
}

NearService.initialize();

export default NearService;
