import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import classNames from 'classnames';
import { motion } from 'framer-motion';
import Button from 'components/Button';
import { BOT_LINK, DAILY_TASK_PRICE, DAILY_TASK_REWARD, TEMPLATE_VIDEO } from 'constants/main';
import useBalanceStore from 'store/balance.store';
import useMainStore from 'store/main.store';
import { showErrorToast, showWarningToast } from 'utils/toastUtils';
import { submitDailyTask } from 'api/tasks';
import { ETaskSlug, ETaskStatus } from 'interface/task';
import useIsOnScreenKeyboardOpen from 'hooks/useIsOnScreenKeyboardOpen';
import useDetectKeyboardOpen from 'use-detect-keyboard-open';
import useTasksStore from 'store/tasks.store';

import './Template.scss';

const getImage = (slug: ETaskSlug) =>
  ({
    [ETaskSlug.DAILY_TELEGRAM]: 'images/task/daily-tg.png',
    [ETaskSlug.DAILY_TIK_TOK]: 'images/task/daily-tik-tok.png',
    [ETaskSlug.DAILY_X]: 'images/task/daily-x.png',
    [ETaskSlug.BASIC_YOUTUBE]: '',
    [ETaskSlug.BASIC_CHAT]: '',
    [ETaskSlug.BASIC_TG]: '',
    [ETaskSlug.BASIC_X]: '',
  }[slug]);

const getShadow = (slug: ETaskSlug) =>
  ({
    [ETaskSlug.DAILY_TELEGRAM]: 'images/template-shadow-1.svg',
    [ETaskSlug.DAILY_TIK_TOK]: 'images/template-shadow-2.svg',
    [ETaskSlug.DAILY_X]: 'images/template-shadow-2.svg',
    [ETaskSlug.BASIC_YOUTUBE]: '',
    [ETaskSlug.BASIC_CHAT]: '',
    [ETaskSlug.BASIC_TG]: '',
    [ETaskSlug.BASIC_X]: '',
  }[slug]);

const getName = (slug: ETaskSlug) =>
  ({
    [ETaskSlug.DAILY_TELEGRAM]: 'Telegram',
    [ETaskSlug.DAILY_TIK_TOK]: 'Tik Tok',
    [ETaskSlug.DAILY_X]: 'X',
    [ETaskSlug.BASIC_YOUTUBE]: '',
    [ETaskSlug.BASIC_CHAT]: '',
    [ETaskSlug.BASIC_TG]: '',
    [ETaskSlug.BASIC_X]: '',
  }[slug]);

const getTextTemplate = (slug: ETaskSlug) =>
  ({
    [ETaskSlug.DAILY_TELEGRAM]:
      "📢 Spread the word and earn rewards with PitchTalk's mini-app! <br/> 🚀 Share posts, invite friends, and get rewarded for helping startups grow. <br/> 🌟 Get started: [PASTE Referral link here] <br/> #PitchTalk #EarnWithPitchTalk #PitchTalk4Fund",
    [ETaskSlug.DAILY_TIK_TOK]:
      "🎥 Wanna earn rewards by promoting startups? <br/> 🚀 Check out PitchTalk's mini-app where your posts can make you money! <br/> 💸 Don't miss out: [PASTE Referral link here] <br/> #PitchTalk #EarnWithPitchTalk #PitchTalk4Fund",
    [ETaskSlug.DAILY_X]:
      '🚀 Introducing @Pitchtalk_com mini-app — share, promote, and earn rewards for supporting the next wave of startups!  <br/> 💼 Start earning today: [PASTE Referral link here] <br/> #PitchTalk #EarnWithPitchTalk #PitchTalk4Fund',
    [ETaskSlug.BASIC_YOUTUBE]: '',
    [ETaskSlug.BASIC_CHAT]: '',
    [ETaskSlug.BASIC_TG]: '',
    [ETaskSlug.BASIC_X]: '',
  }[slug]);

const getTextTemplateForCopy = (slug: ETaskSlug) =>
  ({
    [ETaskSlug.DAILY_TELEGRAM]:
      "📢 Spread the word and earn rewards with PitchTalk's mini-app! \n🚀 Share posts, invite friends, and get rewarded for helping startups grow. \n🌟 Get started: [PASTE Referral link here] \n#PitchTalk #EarnWithPitchTalk #PitchTalk4Fund",
    [ETaskSlug.DAILY_TIK_TOK]:
      "🎥 Wanna earn rewards by promoting startups? \n🚀 Check out PitchTalk's mini-app where your posts can make you money! \n💸 Don't miss out: [PASTE Referral link here] \n#PitchTalk #EarnWithPitchTalk #PitchTalk4Fund",
    [ETaskSlug.DAILY_X]:
      '🚀 Introducing @Pitchtalk_com mini-app — share, promote, and earn rewards for supporting the next wave of startups!  \n💼 Start earning today: [PASTE Referral link here] \n#PitchTalk #EarnWithPitchTalk #PitchTalk4Fund',
    [ETaskSlug.BASIC_YOUTUBE]: '',
    [ETaskSlug.BASIC_CHAT]: '',
    [ETaskSlug.BASIC_TG]: '',
    [ETaskSlug.BASIC_X]: '',
  }[slug]);

const Template: React.FC = () => {
  const [isCopied, setIsCopied] = useState(false);
  const [isCopiedText, setIsCopiedText] = useState(false);
  const [isDownloadingFile, setIsDownloadingFile] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [link, setLink] = useState('');
  const [linkError, setLinkError] = useState<string | null>(null);
  const [isInputFocus, setIsInputFocus] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const user = useMainStore((state) => state.user);
  const coins = useBalanceStore((state) => state.coins);
  const updateCoins = useBalanceStore((state) => state.updateCoins);
  const isVerifying = false;
  const updateDailyTaskStatus = useTasksStore((state) => state.updateDailyTaskStatus);

  const searchParams = new URLSearchParams(location.search);
  const slug = searchParams.get('slug');

  const validateLink = (link: string, slug?: ETaskSlug) => {
    if (!link.startsWith('https')) {
      setLinkError('Invalid url, should start with https');
      return false;
    }

    switch (slug) {
      case ETaskSlug.DAILY_TELEGRAM:
        if (!link.includes('https://')) {
          setLinkError('Invalid url, should contain https');
          return false;
        }
        return true;
      case ETaskSlug.DAILY_TIK_TOK:
        if (!link.includes('tiktok.com')) {
          setLinkError('Invalid url, should contain tiktok.com');
          return false;
        }
        return true;
      case ETaskSlug.DAILY_X:
        if (!link.includes('x.com') && !link.includes('twitter.com')) {
          setLinkError('Invalid url, should contain x.com or twitter.com');
          return false;
        }
        return true;
    }
  };

  useEffect(() => {
    const tg = window.Telegram.WebApp;
    tg.BackButton.show();
    tg.BackButton.onClick(() => {
      navigate(-1);
    });
    return () => {
      tg.BackButton.hide();
    };
  }, [navigate]);

  const onCopy = () => {
    const link = `${BOT_LINK}?startapp=${user?.referralCode}`;
    navigator.clipboard
      .writeText(link)
      .then(() => {
        setIsCopied(true);
        setTimeout(() => {
          setIsCopied(false);
        }, 3000);
      })
      .catch((err) => {
        console.error('Failed to copy: ', err);
      });
  };

  const onCopyText = (text: string) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        setIsCopiedText(true);
        setTimeout(() => {
          setIsCopiedText(false);
        }, 3000);
      })
      .catch((err) => console.error('Failed to copy: ', err));
  };

  const onDownload = async () => {
    try {
      setIsDownloadingFile(true);
      const response = await fetch(TEMPLATE_VIDEO);
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'template.mp4');
      document.body.appendChild(link);
      link.click();
      link.parentNode?.removeChild(link);
    } catch (error) {
      console.log('Error while downloading');
      showWarningToast('Oops something went wrong, use command please.');
    } finally {
      showWarningToast('If something went wrong, use command please.');
      setIsDownloadingFile(false);
    }
  };

  const onSubmit = async () => {
    if (!link || !validateLink(link, slug as ETaskSlug)) {
      return;
    }
    setLinkError('');
    if (coins <= DAILY_TASK_PRICE) {
      showErrorToast('Oops, insufficient balance!');
      return;
    }
    setIsSubmitting(true);
    await submitDailyTask(slug || '', link)
      .then((task) => {
        if (task) {
          updateCoins(coins - DAILY_TASK_PRICE);
          updateDailyTaskStatus(slug || '', ETaskStatus.VERIFY_REQUESTED);
        }
      })
      .finally(() => setIsSubmitting(false));
    navigate(-1);
  };

  const isKeyboardOpenAndroid = useDetectKeyboardOpen();
  const isKeyboardOpenIPhone = false; //useIsOnScreenKeyboardOpen();

  return (
    <motion.div
      className={classNames('template-page', {
        'template-page--focus': (isKeyboardOpenAndroid && isInputFocus) || isKeyboardOpenIPhone,
      })}
      initial={{ opacity: 0 }}
      exit={{ opacity: 0 }}
      animate={{ opacity: 1 }}>
      <div className="template-page-header">
        {slug && <img className="template-img" src={getImage(slug as ETaskSlug)} alt="" />}
        {slug && <img className="template-shadow" src={getShadow(slug as ETaskSlug)} alt="" />}
        <h4>Use Our Template</h4>
        <p className="text">
          Post our template in your {getName(slug as ETaskSlug)} profile and receive a reward
        </p>
        <div>
          <p className="reward">
            <img src="images/ticket.svg" alt="" />+{DAILY_TASK_REWARD} Airdrop Pass
          </p>
          <p className="cost">
            Cost: <span>{DAILY_TASK_PRICE} PitchTalk Points</span> to complete the task
          </p>
        </div>
      </div>
      <div className="template-page-body">
        <div className="referral-link">
          <span>
            {BOT_LINK}?startapp={user?.referralCode}
          </span>
          <img
            onClick={onCopy}
            src={`images/copy-icon${isCopied ? '-success' : '-inverted'}.png`}
            alt=""
          />
        </div>
        <div className="warning">
          <img src="images/warning.png" alt="" />
          <p>To successfully complete the task, you need to include this link in your post.</p>
        </div>
      </div>
      <div className="template-page-list">
        <ol>
          <li>
            <span>1.</span>
            Download our template by button or command <span className="command">/video</span>
            <Button
              color="primary"
              text={
                <>
                  <img src="images/download-icon.svg" alt="" />
                  <span>Download Template</span>
                  {isDownloadingFile && (
                    <div className="loading-circle">
                      <div className="loading-inner" />
                    </div>
                  )}
                </>
              }
              onClick={onDownload}
              disabled={isDownloadingFile}
            />
          </li>
          <li>
            <span>2.</span>
            Post the post in {getName(slug as ETaskSlug)} with our template
          </li>
          <li>
            <span>3.</span>
            Insert your referral link in the caption
          </li>
          <li>
            <span>4.</span>
            Copy and paste the provided text
            <div className="copy-text">
              <p dangerouslySetInnerHTML={{ __html: getTextTemplate(slug as ETaskSlug) }} />
              <img
                onClick={() => onCopyText(getTextTemplateForCopy(slug as ETaskSlug))}
                src={`images/copy-icon${isCopiedText ? '-success' : '-inverted'}.png`}
                alt=""
              />
            </div>
          </li>
        </ol>
      </div>
      <div className="template-page-controls">
        <label>Paste your post link below:</label>
        <input
          value={link}
          onChange={(e) => {
            setLink(e.target.value);
            if (linkError) setLinkError(null);
          }}
          onFocus={() => setIsInputFocus(true)}
          onBlur={() => setIsInputFocus(false)}
        />
        {linkError && <p className="error-message">*{linkError}</p>}
        <Button
          color="secondary"
          text={isVerifying ? 'Verifying...' : 'Submit'}
          onClick={onSubmit}
          disabled={isVerifying || isSubmitting}
        />
      </div>
    </motion.div>
  );
};

export default Template;
