import { IUser } from 'interface/user';
import { WebApp } from 'global';
import { create } from 'zustand';

interface IMainStore {
  user: IUser | null;
  tgWebApp: WebApp | null;
  setUser: (user: IUser | null) => void;
  setUserHotWallet: (wallet: string | null) => void;
  setUserHotWalletBonusClaimed: (isClaimed: boolean) => void;
  setTG: (tgWebApp: WebApp | null) => void;
  updateLevel: (level: number) => void;
  setUserHapiScore: (score: number) => void;
}

const useMainStore = create<IMainStore>((set) => ({
  user: null,
  tgWebApp: null,
  setUser: (user: IUser | null) => set((state) => ({ ...state, user })),
  setUserHotWallet: (wallet: string | null) =>
    set((state) => ({
      ...state,
      user: state.user ? { ...state.user, hotNearWalletAddress: wallet } : null,
    })),
  setUserHotWalletBonusClaimed: (isClaimed: boolean) =>
    set((state) => ({
      ...state,
      user: state.user ? { ...state.user, hotNearWalletBonusClaimed: isClaimed } : null,
    })),
  setTG: (tgWebApp: WebApp | null) => set({ tgWebApp }),
  updateLevel: (level: number) =>
    set((state) => ({
      ...state,
      user: state.user ? { ...state.user, id: level.toString(), level } : null,
    })),
  setUserHapiScore: (hapiScore: number) =>
    set((state) => ({
      ...state,
      user: state.user ? { ...state.user, hapiScore } : null,
    })),
}));

export default useMainStore;
