import { motion } from 'framer-motion';
import Header from 'components/Header';
import useWeb3Store from 'store/web3.store';
import LoadingScreen from 'components/LoadingScreen';
import NoNFTs from './components/NoNFTs';
import ConnectHotWallet from './components/ConnectHotWallet';
import { defaultAnimation } from 'styles/animations';
import {
  getUserNFTsBonuses,
  getUserNFTsDDGrades,
  getUserNFTsGrades,
  getUserNFTsUsmenGrades,
  getUserNFTsYupGrades,
} from 'api/nft';
import useNFTsStore from 'store/nfts.store';
import { EModals } from 'interface/modals';
import useModalStore from 'store/modals.store';
import {
  DD_NFT_LEVEL_BY_GRADE,
  NFTS_ACTIVE,
  PT_NFT_LEVEL_BY_GRADE,
  USMEN_NFT_LEVEL_BY_GRADE,
  YUP_NFT_LEVEL_BY_GRADE,
} from 'constants/nft';
import NFTsList from './components/NFTsList';
import NearService from 'services/NearService';
import { ENFT_TYPE } from 'interface/nft';
import { showErrorToast } from 'utils/toastUtils';
import NFTStaking from 'components/NFTStaking';

import './NFTPage.scss';

const NFTPage: React.FC = () => {
  const nearWallet = useWeb3Store((state) => state.nearWallet);
  const nftsStore = useNFTsStore();
  const showModal = useModalStore((state) => state.showModal);

  const isPTNFTs = !!nftsStore.nfts.size;
  const isHereNFTs = !!nftsStore.hereNfts.size;
  const isHapiNFTs = !!nftsStore.hapiNfts.size;
  const isUsmenNFTs = !!nftsStore.usmenNfts.size;
  const isDDNFTs = !!nftsStore.ddNfts.size;

  const isNFTs = isPTNFTs || isHereNFTs || isHapiNFTs || isUsmenNFTs || isDDNFTs;
  const isConnected = nearWallet?.accountId;

  const onClaimBonus = (type: ENFT_TYPE) => async (tokenId: string) => {
    let level = 1;
    if (type === ENFT_TYPE.PITCHTALK) {
      const grade = nftsStore.getGradeById(tokenId);
      if (!grade) {
        showErrorToast('Unable to receive nft metadata, please try again later');
        return;
      }
      level = PT_NFT_LEVEL_BY_GRADE.get(grade) || 10;
    } else if (type === ENFT_TYPE.HOT) {
      level = 10;
    } else if (type === ENFT_TYPE.HAPI) {
      level = 0;
    } else if (type === ENFT_TYPE.USMEN) {
      const grade = nftsStore.getUsmenGradeById(tokenId);
      if (!grade) {
        showErrorToast('Unable to receive nft metadata, please try again later');
        return;
      }
      level = USMEN_NFT_LEVEL_BY_GRADE.get(grade) || 2;
    } else if (type === ENFT_TYPE.DD) {
      const grade = nftsStore.getDDGradeById(tokenId);
      if (!grade) {
        showErrorToast('Unable to receive nft metadata, please try again later');
        return;
      }
      level = DD_NFT_LEVEL_BY_GRADE.get(grade) || 2;
    } else if (type === ENFT_TYPE.YUP) {
      const grade = nftsStore.getYupGradeById(tokenId);
      if (!grade) {
        showErrorToast('Unable to receive nft metadata, please try again later');
        return;
      }
      level = YUP_NFT_LEVEL_BY_GRADE.get(grade) || 2;
    }
    showModal(EModals.NFT_REWARD, { level, nftId: tokenId, type });
  };

  const refreshNFTs = (type: ENFT_TYPE) => async () => {
    if (type === ENFT_TYPE.PITCHTALK) {
      const ptNfts = await NearService.getUserWeb3NFTs(nearWallet?.accountId || '', type);
      const grades = await getUserNFTsGrades(ptNfts);
      const ptBonuses = await getUserNFTsBonuses(
        ptNfts.map((nft) => nft.token_id),
        ENFT_TYPE.PITCHTALK,
      );
      nftsStore.setNFTs(ptNfts);
      nftsStore.setGrades(grades);
      nftsStore.setBonuses(ptBonuses);
    } else if (type === ENFT_TYPE.HOT) {
      const hereNfts = await NearService.getUserWeb3NFTs(nearWallet?.accountId || '', type);
      const hereBonuses = await getUserNFTsBonuses(
        hereNfts.map((nft) => nft.token_id),
        ENFT_TYPE.HOT,
      );
      nftsStore.setHereNFTs(hereNfts);
      nftsStore.setHereBonuses(hereBonuses);
    } else if (type === ENFT_TYPE.HAPI) {
      const hapiNfts = await NearService.getUserWeb3NFTs(nearWallet?.accountId || '', type);
      const hapiBonuses = await getUserNFTsBonuses(
        hapiNfts.map((nft) => nft.token_id),
        ENFT_TYPE.HAPI,
      );
      nftsStore.setHapiNFTs(hapiNfts);
      nftsStore.setHapiBonuses(hapiBonuses);
    } else if (type === ENFT_TYPE.USMEN) {
      const usmenNfts = await NearService.getUserWeb3NFTs(nearWallet?.accountId || '', type);
      const usmenGrades = await getUserNFTsUsmenGrades(usmenNfts);
      const usmenBonuses = await getUserNFTsBonuses(
        usmenNfts.map((nft) => nft.token_id),
        ENFT_TYPE.USMEN,
      );
      nftsStore.setUsmenNFTs(usmenNfts);
      nftsStore.setUsmenGrades(usmenGrades);
      nftsStore.setUsmenBonuses(usmenBonuses);
    } else if (type === ENFT_TYPE.DD) {
      const ddNfts = await NearService.getUserWeb3NFTs(nearWallet?.accountId || '', type);
      const ddGrades = await getUserNFTsDDGrades(ddNfts);
      const ddBonuses = await getUserNFTsBonuses(
        ddNfts.map((nft) => nft.token_id),
        ENFT_TYPE.DD,
      );
      nftsStore.setDDNFTs(ddNfts);
      nftsStore.setDDBonuses(ddBonuses);
      nftsStore.setDDGrades(ddGrades);
    } else if (type === ENFT_TYPE.YUP) {
      const yupNfts = await NearService.getUserWeb3NFTs(nearWallet?.accountId || '', type);
      const yupGrades = getUserNFTsYupGrades(yupNfts);
      const yupBonuses = await getUserNFTsBonuses(
        yupNfts.map((nft) => nft.token_id),
        ENFT_TYPE.YUP,
      );
      nftsStore.setYupNFTs(yupNfts);
      nftsStore.setYupBonuses(yupBonuses);
      nftsStore.setYupGrades(yupGrades);
    }
  };

  return (
    <motion.div className="nft-page" {...defaultAnimation}>
      <Header />
      <div className="nft-page-body">
        <div className="nft-page-body-content">
          {nftsStore.isLoadingNFT ? (
            <LoadingScreen />
          ) : isConnected ? (
            <>
              {isNFTs && <NFTStaking />}
              <h2 className="nft-page-body-content-title">My Collection</h2>
              {isNFTs ? (
                <>
                  <NFTsList
                    name="Pitchtalk"
                    type={ENFT_TYPE.PITCHTALK}
                    nfts={nftsStore.nfts}
                    bonuses={nftsStore.bonuses}
                    refreshNFTs={refreshNFTs(ENFT_TYPE.PITCHTALK)}
                    onClaimBonus={onClaimBonus(ENFT_TYPE.PITCHTALK)}
                  />
                  {NFTS_ACTIVE[ENFT_TYPE.HOT] && (
                    <NFTsList
                      name="HOT"
                      type={ENFT_TYPE.HOT}
                      nfts={nftsStore.hereNfts}
                      bonuses={nftsStore.hereBonuses}
                      refreshNFTs={refreshNFTs(ENFT_TYPE.HOT)}
                      onClaimBonus={onClaimBonus(ENFT_TYPE.HOT)}
                    />
                  )}
                  {NFTS_ACTIVE[ENFT_TYPE.HAPI] && (
                    <NFTsList
                      name="HAPI"
                      type={ENFT_TYPE.HAPI}
                      nfts={nftsStore.hapiNfts}
                      bonuses={nftsStore.hapiBonuses}
                      refreshNFTs={refreshNFTs(ENFT_TYPE.HAPI)}
                      onClaimBonus={onClaimBonus(ENFT_TYPE.HAPI)}
                    />
                  )}
                  {NFTS_ACTIVE[ENFT_TYPE.USMEN] && (
                    <NFTsList
                      name="USMen"
                      type={ENFT_TYPE.USMEN}
                      nfts={nftsStore.usmenNfts}
                      bonuses={nftsStore.usmenBonuses}
                      refreshNFTs={refreshNFTs(ENFT_TYPE.USMEN)}
                      onClaimBonus={onClaimBonus(ENFT_TYPE.USMEN)}
                    />
                  )}
                  {NFTS_ACTIVE[ENFT_TYPE.DD] && (
                    <NFTsList
                      name="DD"
                      type={ENFT_TYPE.DD}
                      nfts={nftsStore.ddNfts}
                      bonuses={nftsStore.ddBonuses}
                      refreshNFTs={refreshNFTs(ENFT_TYPE.DD)}
                      onClaimBonus={onClaimBonus(ENFT_TYPE.DD)}
                    />
                  )}
                  {NFTS_ACTIVE[ENFT_TYPE.YUP] && (
                    <NFTsList
                      name="Yupik"
                      type={ENFT_TYPE.YUP}
                      nfts={nftsStore.yupNfts}
                      bonuses={nftsStore.yupBonuses}
                      refreshNFTs={refreshNFTs(ENFT_TYPE.YUP)}
                      onClaimBonus={onClaimBonus(ENFT_TYPE.YUP)}
                    />
                  )}
                </>
              ) : (
                <NoNFTs />
              )}
            </>
          ) : (
            <ConnectHotWallet />
          )}
        </div>
      </div>
    </motion.div>
  );
};

export default NFTPage;
