export enum ENFT_TYPE {
  PITCHTALK = 'pitchtalk',
  HOT = 'hot',
  HAPI = 'hapi',
  USMEN = 'usmen',
  DD = 'doubleDog',
  YUP = 'yup',
}

export interface INFTMetadata {
  title?: string;
  description?: string;
  media?: string;
  media_hash?: string;
  copies?: number;
  issued_at?: string;
  expires_at?: string;
  starts_at?: string;
  updated_at?: string;
  extra?: string;
  reference?: string;
  reference_hash?: string;
}

export interface INFTToken {
  token_id: string;
  owner_id: string;
  metadata: INFTMetadata;
}

export type INFTTokens = INFTToken[];

export interface INFTAtribute {
  trait_type: string;
  value: string;
}

export interface INFTLevelsResponse {
  attributes: INFTAtribute[];
}

export enum ENFT_GRADE {
  Whale = 'Whale',
  Shark = 'Shark',
  Unicorn = 'Unicorn',
  Guru = 'Guru',
  ICO = 'ICO',
  Flipper = 'Flipper',
  Farmer = 'Farmer',
  Holder = 'Holder',
  Moon = 'Moon',
  Noob = 'Noob',
}

export enum ENFT_USMEN_GRADE {
  Rare = 'Rare',
  Common = 'Common',
}

export enum ENFT_YUP_GRADE {
  Zero = '0',
  One = '1',
  Two = '2',
  Three = '3',
  Four = '4',
  Legendary = 'Legendary',
  Epic = 'Epic',
  Rare = 'Rare',
  Uncommon = 'Uncommon',
  Common = 'Common',
}

export enum ENFT_DD_GRADE {
  Rare = 'Rare',
  Regular = 'Regular',
}
