import { useCallback, useEffect } from 'react';
import useWeb3Store from 'store/web3.store';
import { setupHotWallet } from '@hot-wallet/sdk/adapter/near';
import { setupWalletSelector } from '@near-wallet-selector/core';
import { setupMyNearWallet } from '@near-wallet-selector/my-near-wallet';
import useMainStore from 'store/main.store';
import { setupModal } from '@near-wallet-selector/modal-ui';
import {
  getUserNFTs,
  getUserNFTsBonuses,
  getUserNFTsDDGrades,
  getUserNFTsGrades,
  getUserNFTsUsmenGrades,
  getUserNFTsYupGrades,
} from 'api/nft';
import useNFTsStore from 'store/nfts.store';
import useModalStore from 'store/modals.store';
import { EModals } from 'interface/modals';
import { ENFT_TYPE } from 'interface/nft';
import { isMock } from 'App';

const network = 'mainnet';
const myNearWalletUrl = 'https://app.mynearwallet.com/';
const hapiProvider = 'https://near-rpc.hapilabs.one';
const mainnetProvider = 'https://rpc.mainnet.near.org';

export const useNearWallet = () => {
  const showModal = useModalStore((state) => state.showModal);
  const user = useMainStore((state) => state.user);
  const {
    setNFTs,
    setGrades,
    setBonuses,
    setHereNFTs,
    setHereBonuses,
    setHapiNFTs,
    setHapiBonuses,
    setUsmenNFTs,
    setUsmenBonuses,
    setUsmenGrades,
    setDDNFTs,
    setDDBonuses,
    setDDGrades,
    setYupNFTs,
    setYupBonuses,
    setYupGrades,
    setIsLoadingNFT,
  } = useNFTsStore();
  const setNearWallet = useWeb3Store((state) => state.setNearWallet);
  const setModal = useWeb3Store((state) => state.setModal);

  const initSelector = useCallback(async () => {
    const selector = await setupWalletSelector({
      // @ts-ignore
      modules: [
        setupHotWallet(),
        isMock ? setupMyNearWallet({ walletUrl: myNearWalletUrl }) : null,
      ].filter(Boolean),
      network,
      fallbackRpcUrls: [mainnetProvider, hapiProvider],
    });
    const modal = setupModal(selector, { contractId: '' });
    return { selector, modal };
  }, []);

  useEffect(() => {
    if (!user?.id) return;

    initSelector().then(async ({ selector, modal }) => {
      try {
        const wallet = await selector.wallet();
        const accounts = await wallet.getAccounts();
        const accountId = accounts?.[0]?.accountId;
        const walletWithAccountId = { ...wallet, accountId };
        if (accountId) {
          if (!user.hotNearWalletBonusClaimed) {
            showModal(EModals.CONNECT_HOT_REWARD, {
              onSuccess: () => setNearWallet(walletWithAccountId),
              signMessage: wallet.signMessage,
            });
          } else {
            if (user.hotNearWalletAddress !== accountId) {
              showModal(EModals.CONNECT_HOT_REWARD, {
                isConfirming: true,
                onSuccess: () => setNearWallet(walletWithAccountId),
                signMessage: wallet.signMessage,
              });
            }
          }
        }
        setNearWallet(walletWithAccountId);
      } catch (error) {
        console.log(error);
      }

      selector.on('signedIn', async () => {
        const wallet = await selector.wallet();
        const accounts = await wallet.getAccounts();
        const accountId = accounts?.[0]?.accountId;
        const walletWithAccountId = { ...wallet, accountId };
        if (accountId) {
          if (!user.hotNearWalletBonusClaimed) {
            showModal(EModals.CONNECT_HOT_REWARD, {
              onSuccess: () => setNearWallet(walletWithAccountId),
              signMessage: wallet.signMessage,
            });
          } else {
            if (user.hotNearWalletAddress !== accountId) {
              showModal(EModals.CONNECT_HOT_REWARD, {
                isConfirming: true,
                onSuccess: () => setNearWallet(walletWithAccountId),
                signMessage: wallet.signMessage,
              });
            }
          }
        }
        setNearWallet(walletWithAccountId);
      });

      selector.on('signedOut', async () => {
        setNearWallet(null);
      });

      setModal(modal);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.id, user?.hotNearWalletBonusClaimed, user?.hotNearWalletAddress]);

  useEffect(() => {
    const getNFTs = async () => {
      if (user?.hotNearWalletAddress && user?.hotNearWalletBonusClaimed) {
        try {
          setIsLoadingNFT(true);
          const { pt, here, hapi, usmen, dd, yup } = await getUserNFTs(user?.hotNearWalletAddress);
          const grades = await getUserNFTsGrades(pt);
          const usmenGrades = await getUserNFTsUsmenGrades(usmen);
          const ddGrades = await getUserNFTsDDGrades(dd);
          const yupGrades = getUserNFTsYupGrades(yup);

          const bonuses = await getUserNFTsBonuses(
            pt.map((nft) => nft.token_id),
            ENFT_TYPE.PITCHTALK,
          );
          const hereBonuses = await getUserNFTsBonuses(
            here.map((nft) => nft.token_id),
            ENFT_TYPE.HOT,
          );
          const hapiBonuses = await getUserNFTsBonuses(
            hapi.map((nft) => nft.token_id),
            ENFT_TYPE.HAPI,
          );
          const usmenBonuses = await getUserNFTsBonuses(
            usmen.map((nft) => nft.token_id),
            ENFT_TYPE.USMEN,
          );
          const ddBonuses = await getUserNFTsBonuses(
            dd.map((nft) => nft.token_id),
            ENFT_TYPE.DD,
          );
          const yupBonuses = await getUserNFTsBonuses(
            yup.map((nft) => nft.token_id),
            ENFT_TYPE.YUP,
          );

          setNFTs(pt);
          setHereNFTs(here);
          setHapiNFTs(hapi);
          setUsmenNFTs(usmen);
          setDDNFTs(dd);
          setYupNFTs(yup);

          setGrades(grades);
          setBonuses(bonuses);

          setHereBonuses(hereBonuses);

          setHapiBonuses(hapiBonuses);

          setUsmenBonuses(usmenBonuses);
          setUsmenGrades(usmenGrades);

          setDDBonuses(ddBonuses);
          setDDGrades(ddGrades);

          setYupBonuses(yupBonuses);
          setYupGrades(yupGrades);
        } catch (error) {
          console.log(error);
        } finally {
          setIsLoadingNFT(false);
        }
      }
    };
    getNFTs();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.hotNearWalletAddress]);
};
