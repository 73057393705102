import React from 'react';
import classNames from 'classnames';

import './Tasks.scss';

interface ITasksProps {
  label: string;
  completed: number;
  isDisabled?: boolean;
  isRow?: boolean;
  className?: string;
}

const Tasks: React.FC<React.PropsWithChildren<ITasksProps>> = ({
  label,
  completed,
  isDisabled,
  isRow = false,
  className,
  children,
}) => {
  const totalTasks = React.Children.count(children);

  return (
    <div
      className={classNames(
        'tasks-list',
        {
          'tasks-list__row': isRow,
          'tasks-list__column': !isRow,
          'tasks-list--disabled': isDisabled,
        },
        className,
      )}>
      <h4>
        {label}{' '}
        <span>
          {completed}
          <span className="total-tasks">/{totalTasks}</span>
        </span>
      </h4>
      <ul className={classNames({ 'disabled-list': isDisabled })}>
        {React.Children.map(children, (child) => child)}
      </ul>
      {isDisabled && <p className="disable-reason">Complete the general tasks first</p>}
    </div>
  );
};

export default Tasks;
