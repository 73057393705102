import { ETaskStatus } from 'interface/task';

const statusRank: Record<ETaskStatus, number> = {
  [ETaskStatus.INITIAL]: 1,
  [ETaskStatus.VERIFY_REQUESTED]: 2,
  [ETaskStatus.VERIFY_REJECTED]: 3,
  [ETaskStatus.COMPLETED_NOT_CLAIMED]: 4,
  [ETaskStatus.COMPLETED_CLAIMED]: 5,
};

export const compareTasksByStatus = (status1: ETaskStatus, status2: ETaskStatus) => {
  return statusRank[status1] - statusRank[status2];
};
