import React from 'react';

import './CoomingSoon.scss';

const withComingSoon = <P extends { key?: string | number }>(
  WrappedComponent: React.ComponentType<Omit<P, 'key'>>,
) => {
  return ({ key, ...props }: P) => {
    return (
      <div style={{ position: 'relative', display: 'inline-block' }} key={key}>
        <div className="coming-soon-wrapper--filter">
          <WrappedComponent {...props} />
        </div>

        <div className="coming-soon-wrapper">
          <span>Coming Soon...</span>
        </div>
      </div>
    );
  };
};

export default withComingSoon;
