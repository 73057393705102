import { DAILY_TASKS, PARTNERS_TASKS } from 'constants/tasks';
import { ETaskStatus, ITaskProgress } from 'interface/task';
import { create } from 'zustand';

export interface ITasksStore {
  dailyTasks: Map<string, ITaskProgress>;
  setDailyTasks: (dailyTasks: ITaskProgress[]) => void;
  generalTasks: Map<string, ITaskProgress>;
  setGeneralTasks: (generalTasks: ITaskProgress[]) => void;
  partnersTasks: Map<string, ITaskProgress>;
  setPartnersTasks: (partnersTasks: ITaskProgress[]) => void;

  updateGeneralTaskStatus: (id: string, newStatus: ETaskStatus) => void;
  updateDailyTaskStatus: (slug: string, newStatus: ETaskStatus) => void;
  updatePartnerTaskStatus: (slug: string, newStatus: ETaskStatus) => void;
  updateTask: (slug: string, updatedTask: Partial<ITaskProgress>) => void;
}

const useTasksStore = create<ITasksStore>((set, get) => ({
  generalTasks: new Map<string, ITaskProgress>(),
  dailyTasks: new Map<string, ITaskProgress>(DAILY_TASKS.map((task) => [task.template.slug, task])),
  partnersTasks: new Map<string, ITaskProgress>(
    PARTNERS_TASKS.map((task) => [task.template.slug, task]),
  ),

  setDailyTasks: (dailyTasks: ITaskProgress[]) =>
    set({
      dailyTasks: new Map(
        dailyTasks.map((task) => [task.template.slug, { ...task, status: ETaskStatus.INITIAL }]),
      ),
    }),
  setGeneralTasks: (generalTasks: ITaskProgress[]) =>
    set({ generalTasks: new Map(generalTasks.map((task) => [task.template.slug, task])) }),
  setPartnersTasks: (partnersTasks: ITaskProgress[]) =>
    set({
      partnersTasks: new Map(
        partnersTasks.map((task) => [task.template.slug, { ...task, status: ETaskStatus.INITIAL }]),
      ),
    }),

  updateGeneralTaskStatus: (id: string, newStatus: ETaskStatus) => {
    set((state) => {
      const task = Array.from(state.generalTasks.values()).find((task) => task.id === id);
      if (task) {
        task.status = newStatus;
        return {
          generalTasks: new Map(state.generalTasks),
        };
      }
      return state;
    });
  },
  updateDailyTaskStatus: (slug: string, newStatus: ETaskStatus) => {
    set((state) => {
      const task = state.dailyTasks.get(slug);
      if (task) {
        const updatedTask = { ...task, status: newStatus };
        return {
          dailyTasks: new Map(state.dailyTasks).set(slug, updatedTask),
        };
      }
      return state;
    });
  },
  updatePartnerTaskStatus: (slug: string, newStatus: ETaskStatus) => {
    set((state) => {
      const task = state.partnersTasks.get(slug);
      if (task) {
        const updatedTask = { ...task, status: newStatus };
        return {
          partnersTasks: new Map(state.partnersTasks).set(slug, updatedTask),
        };
      }
      return state;
    });
  },
  updateTask: (slug: string, updatedTask: Partial<ITaskProgress>) => {
    set((state) => {
      const task = state.partnersTasks.get(slug);
      const updates: ITaskProgress = { ...task, ...updatedTask } as ITaskProgress;
      return {
        partnersTasks: new Map(state.partnersTasks).set(slug, updates),
      };
    });
  },
}));

export default useTasksStore;
