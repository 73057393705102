import React from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { createGift } from 'api/gits';
import { showSuccessToast } from 'utils/toastUtils';
import { NavLink } from 'react-router-dom';

import './AdminGift.scss';

const GiftSchema = Yup.object().shape({
  username: Yup.string().required('Username is required'),
  coins: Yup.number().required('Coins is required').positive('Value must be positive'),
  tickets: Yup.number().required('Tickets is required').positive('Value must be positive'),
  description: Yup.string().required('Description is required'),
});

const AdminGift: React.FC = () => {
  const formik = useFormik({
    initialValues: { username: '', coins: 0, tickets: 0, description: '' },
    validationSchema: GiftSchema,
    onSubmit: async (values, helpers) => {
      const gift = await createGift(values);
      if (gift) {
        showSuccessToast('Gift created successfully!');
        helpers.resetForm();
      }
    },
  });

  return (
    <div className="admin-gift-page">
      <NavLink className="back-link" to="/admin">
        ← Back
      </NavLink>
      <h1>Create Gift</h1>
      <form onSubmit={formik.handleSubmit}>
        <div className="form-item">
          <label htmlFor="username">Username</label>
          <input
            type="text"
            name="username"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.username}
          />
          {formik.touched.username && formik.errors.username ? (
            <p>{formik.errors.username}</p>
          ) : null}
        </div>
        <div className="form-item">
          <label htmlFor="coins">Coins</label>
          <input
            type="number"
            name="coins"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.coins}
          />
          {formik.touched.coins && formik.errors.coins ? <p>{formik.errors.coins}</p> : null}
        </div>
        <div className="form-item">
          <label htmlFor="tickets">Tickets</label>
          <input
            type="number"
            name="tickets"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.tickets}
          />
          {formik.touched.tickets && formik.errors.tickets ? <p>{formik.errors.tickets}</p> : null}
        </div>
        <div className="form-item">
          <label htmlFor="description">Description</label>
          <input
            type="text"
            name="description"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.description}
          />
          {formik.touched.description && formik.errors.description ? (
            <p>{formik.errors.description}</p>
          ) : null}
        </div>
        <button type="submit" disabled={formik.isSubmitting}>
          Create Gift
        </button>
      </form>
    </div>
  );
};

export default AdminGift;
