import Button from 'components/Button';
import { AnimatePresence, motion } from 'framer-motion';
import { ENFT_TYPE, INFTToken } from 'interface/nft';
import React, { useCallback, useState } from 'react';
import { defaultAnimation } from 'styles/animations';
import NoNFTsMessage from './NoNFTsMessage';
import classNames from 'classnames';
import { EModals } from 'interface/modals';
import useModalStore from 'store/modals.store';
import { formatYUPImageUrl } from 'utils/nft';

interface INFTsListProps {
  name: string;
  type: ENFT_TYPE;
  nfts: Map<string, INFTToken>;
  bonuses: Map<string, boolean>;
  refreshNFTs: () => Promise<void>;
  onClaimBonus: (tokenId: string) => void;
}

const NFTsList: React.FC<INFTsListProps> = ({
  name,
  type,
  nfts,
  bonuses,
  refreshNFTs,
  onClaimBonus,
}) => {
  const { showModal } = useModalStore();
  const [isOpened, setIsOpened] = useState(false);
  const [isReloading, setIsReloading] = useState(false);

  const sortedNfts = Array.from(nfts.values()).sort(
    (a, b) => Number(!!bonuses.get(a.token_id)) - Number(!!bonuses.get(b.token_id)),
  );

  const onReload = async (e: React.MouseEvent) => {
    if (isReloading) return;
    e.preventDefault();
    e.stopPropagation();
    setIsReloading(true);
    await refreshNFTs().finally(() => setIsReloading(false));
  };

  const onInfoClick = useCallback(
    (e: React.MouseEvent) => {
      e.preventDefault();
      e.stopPropagation();
      showModal(EModals.NFTS_INFO_MODAL, { type });
    },
    [showModal, type],
  );

  const getNFTImage = useCallback(
    (nft: INFTToken) => {
      if (type === ENFT_TYPE.YUP) {
        return formatYUPImageUrl(nft.metadata.media || '');
      }
      return nft.metadata.media || '';
    },
    [type],
  );

  return (
    <>
      <div
        className={classNames('nft-page-body-content-list-header', {
          'nft-page-body-content-list-header--opened': isOpened,
        })}
        onClick={() => setIsOpened((p) => !p)}>
        <div className="nft-page-body-content-list-header-count">
          <p>{name} NFTs</p>
          <p
            className={classNames('nft-page-body-content-list-header-count-amount', {
              'nft-page-body-content-list-header-count-amount--reloading': isReloading,
            })}>
            {sortedNfts.length}
            <img
              onClick={onReload}
              src="images/reload.png"
              alt="Reload"
              style={{ width: '20px', height: '20px' }}
            />
          </p>
          <img
            onClick={onInfoClick}
            src="images/info-icon.png"
            alt="Info"
            style={{ width: '28px', height: '28px' }}
          />
        </div>
        <img
          className="arrow-image"
          src="images/arrow.svg"
          alt="Arrow"
          style={{ width: '16px', height: '16px' }}
        />
      </div>
      <AnimatePresence mode="wait" initial={true}>
        {isOpened ? (
          sortedNfts.length ? (
            <motion.div className="nft-page-body-content-list nfts-list" {...defaultAnimation}>
              <ul
                style={{
                  opacity: isReloading ? 0.5 : 1,
                  filter: isReloading ? 'blur(5px)' : 'none',
                  pointerEvents: isReloading ? 'none' : 'auto',
                }}>
                {sortedNfts.map((nft) => (
                  <li key={nft.token_id} className="nft-page-body-content-list-item">
                    <img
                      style={{ width: '160px', height: '160px' }}
                      src={getNFTImage(nft)}
                      alt={nft.metadata.title}
                      onError={(e) => {
                        (e.target as HTMLImageElement).src = 'images/nft-not-found.png';
                      }}
                    />
                    <p>#{nft.token_id}</p>
                    <Button
                      disabled={!!bonuses.get(nft.token_id)}
                      color={!!bonuses.get(nft.token_id) ? 'grey' : 'primary'}
                      text={
                        <>
                          {!!bonuses.get(nft.token_id) ? 'Claimed' : 'Claim Bonus'}
                          <img src="images/prize.svg" alt="" />
                        </>
                      }
                      onClick={() => onClaimBonus(nft.token_id)}
                    />
                  </li>
                ))}
              </ul>
            </motion.div>
          ) : (
            <NoNFTsMessage type={type} />
          )
        ) : null}
      </AnimatePresence>
    </>
  );
};

export default NFTsList;
