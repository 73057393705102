import React from 'react';
import { EModals } from 'interface/modals';

const modalCache = new Map();

const importModalComponent = (path: string) => React.lazy(() => import(`${path}`));

export const lazyImportModal = (modalType: EModals) => {
  if (modalCache.has(modalType)) {
    return modalCache.get(modalType);
  } else {
    let path;
    switch (modalType) {
      case EModals.FAQ:
        path = './FAQModal';
        break;
      case EModals.GIFT:
        path = './GiftModal';
        break;
      case EModals.LEVEL_UPGRADE:
        path = './LevelUpgradeModal';
        break;
      case EModals.BOOST:
        path = './BoostModal';
        break;
      case EModals.DAILY_REWARD:
        path = './DailyRewardModal';
        break;
      case EModals.NFT_REWARD:
        path = './NFTRewardModal';
        break;
      case EModals.NFTS_INFO_MODAL:
        path = './NFTsInfoModal';
        break;
      case EModals.NFT_STAKING_INFO_MODAL:
        path = './NFTStakingInfoModal';
        break;
      case EModals.NFT_UNSTAKE_MODAL:
        path = './NFTUnstakeModal';
        break;
      case EModals.NFT_STAKING_SELECT_MODAL:
        path = './NFTStakingSelectModal';
        break;
      case EModals.CONNECT_HOT_REWARD:
        path = './ConnectHotReward';
        break;
      case EModals.DISCONNECT_WALLET:
        path = './DisconnectWalletModal';
        break;
      case EModals.FITTON_CONNECT:
        path = './FittonConnectModal';
        break;

      default:
        return null;
    }
    const LazyComponent = importModalComponent(path);
    modalCache.set(modalType, LazyComponent);
    return LazyComponent;
  }
};
