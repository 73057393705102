import React, { useState, useEffect } from 'react';
import Button from 'components/Button';
import useBalanceStore from 'store/balance.store';
import { claimFarming, createFarming, getFarming } from 'api/farming';
import useFarmingStore from 'store/farming.store';
import { LEVELS } from 'constants/levels';
import useMainStore from 'store/main.store';
import { SPEED_BOOST, TIME_BOOST } from 'constants/boost';
import { hexToRgba } from 'utils/color';
import { formatClaimTime } from 'utils/time';

import './Claim.scss';

const Claim: React.FC = () => {
  const currentLevel = useMainStore((state) => state?.user?.level);
  const isLoadingFarming = useFarmingStore((state) => state.isLoading);
  const farming = useFarmingStore((state) => state.farming);
  const setIsLoading = useFarmingStore((state) => state.setIsLoading);
  const setFarming = useFarmingStore((state) => state.setFarming);
  const updateCoins = useBalanceStore((state) => state.updateCoins);
  const updateTickets = useBalanceStore((state) => state.updateTickets);
  const [coinsFarming, setCoinsFarming] = useState(0);
  const [timeUntilClaim, setTimeUntilClaim] = useState(0);

  const levelInfo = LEVELS[currentLevel as keyof typeof LEVELS];
  const speedBoostInfo = SPEED_BOOST.get(farming?.speedBoostLevel || 1);
  const timeBoostInfo = TIME_BOOST.get(farming?.timeBoostLevel || 1);

  const levelBonus = levelInfo.bonus;

  const farmingMaxHours = timeBoostInfo?.miningTime || 6;
  const coinsPerHour = speedBoostInfo?.miningSpeed || 150;

  const farmingMaxCoinsPerHour = coinsPerHour * levelBonus;
  const farmingMaxReward = farmingMaxHours * farmingMaxCoinsPerHour;

  const color = levelInfo.color;

  useEffect(() => {
    if (farming) {
      const calculateFarmingTokens = () => {
        const now = new Date();
        const endTime = new Date(farming?.endTime);
        const farmingMaxHoursInMillis = farmingMaxHours * 3600 * 1000;
        const startTime = new Date(endTime.getTime() - farmingMaxHoursInMillis);
        const elapsedTimeInSeconds = Math.min(
          (now.getTime() - startTime.getTime()) / 1000,
          farmingMaxHours * 3600,
        );
        const elapsedHours = elapsedTimeInSeconds / 3600;
        const totalTokens = Math.min(elapsedHours * farmingMaxCoinsPerHour, farmingMaxReward);

        setCoinsFarming(Number(totalTokens.toFixed(0)));

        const remainingTimeInSeconds = Math.max(0, (endTime.getTime() - now.getTime()) / 1000);
        setTimeUntilClaim(remainingTimeInSeconds);
      };

      calculateFarmingTokens();
      const interval = setInterval(() => {
        calculateFarmingTokens();
      }, 60000);

      return () => clearInterval(interval);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [farming?.endTime, farmingMaxHours]);

  const onButtonClick = async () => {
    try {
      setIsLoading(true);
      if (farming?.endTime) {
        const user = await claimFarming();
        if (user) {
          updateCoins(user.coins);
          updateTickets(user.tickets);
          const farming = await getFarming();
          setFarming(farming);
        }
      } else {
        const farming = await createFarming();
        setFarming(farming);
      }
    } catch (error) {
      console.error('Error claiming farming', error);
    } finally {
      setIsLoading(false);
    }
  };

  const isNewUser = isLoadingFarming ? true : !farming;

  const progressPercent = (coinsFarming / farmingMaxReward) * 100;

  return (
    <div className="claim-container">
      <Button
        className={isLoadingFarming ? 'loading' : ''}
        style={{
          '--progress-width': `${isLoadingFarming ? 100 : progressPercent}%`,
          '--claim-color': hexToRgba(color, 1),
          '--claim-color-1': hexToRgba(color, 0.1),
          '--claim-color-2': hexToRgba(color, 0.2),
        }}
        color={isNewUser ? 'white' : 'primary'}
        disabled={isNewUser ? false : coinsFarming < farmingMaxReward}
        text={
          isNewUser ? (
            'Start Farming'
          ) : coinsFarming < farmingMaxReward ? (
            <>
              <span className="text-thin">Farming...</span>
              <span>{coinsFarming < 0 ? 0 : coinsFarming}</span>
            </>
          ) : (
            <>
              Claim <span>{coinsFarming}</span> <img src="images/point.png" alt="" />
            </>
          )
        }
        onClick={onButtonClick}
      />
      {isNewUser ? null : coinsFarming >= farmingMaxReward ? (
        <p>Claim your points to keep farming</p>
      ) : (
        <p>Claim in {formatClaimTime(timeUntilClaim)}</p>
      )}
    </div>
  );
};

export default Claim;
