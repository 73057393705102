import { showErrorToast } from 'utils/toastUtils';

export const handleApiError = (error: any): void => {
  if (error.response) {
    const status = error.response.status;

    if (status >= 500) {
      if (error?.response?.data?.message?.includes('This proof link is already used')) {
        showErrorToast('This proof has already been used by another user!');
      } else if (error?.response?.data?.message?.includes('NFT already used to receive')) {
        showErrorToast('NFT already used to receive rewards!');
      } else if (error?.response?.data?.message?.includes('User not found')) {
        showErrorToast('User not found!');
      } else if (error?.response?.data?.message?.includes('User do not have a hapi score!')) {
        showErrorToast('You do not have a hapi score, mint it to get rewards!');
      } else {
        showErrorToast('Server error, please try again later!');
      }
    } else if (status === 401) {
      showErrorToast('Unauthorized, please restart app!');
    } else if (status === 403) {
      showErrorToast('Forbidden, you do not have permission to perform this action!');
    } else if (status === 400) {
      showErrorToast('Bad request, please check the entered data!');
    } else {
      showErrorToast(`Error: ${error.response.data?.message || 'Something went wrong!'}`);
    }
  } else if (error.request) {
    showErrorToast('No response from the server. Please check your internet connection!');
  } else {
    showErrorToast(`Request error: ${error.message}`);
  }
};
