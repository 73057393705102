const imagesToPreload = [
  'images/leader-1.png',
  'images/leader-2.png',
  'images/leader-3.png',

  'images/daily-prize.gif',

  'images/ticket.svg',
  'images/point.png',
  'images/hot.png',

  'images/level/1.svg',

  'images/task/daily-tg.png',
  'images/task/daily-tik-tok.png',
  'images/task/daily-x.png',

  'images/task/general-tg.svg',
  'images/task/general-x.svg',
  'images/task/general-youtube.svg',

  'images/arrow.svg',
  'images/check-icon.png',
  'images/close-icon.png',
  'images/copy-icon-inverted.png',
  'images/copy-icon.png',
  'images/copy-icon-success.png',
  'images/download-icon.svg',

  'images/task-completed-icon.png',
  'images/task-review-icon.png',
  'images/shadow.png',

  'images/toast-error-icon.png',
  'images/toast-success-icon.png',
  'images/warning.png',

  'images/help.png',

  'images/prize.svg',
  'images/shine.svg',
  'images/reload.png',
  'images/connect-hot-wallet.svg',
  'images/no-nfts-image.png',
];

export const preloadImages = async () => {
  const loadImage = (src: string): Promise<void> => {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.src = src;
      img.onload = () => resolve();
      img.onerror = reject;
    });
  };

  try {
    await Promise.all(imagesToPreload.map((src) => loadImage(src)));
    console.log('All images preloaded');
  } catch (error) {
    console.error('Error preloading images:', error);
  }
};

export const preloadFonts = async () => {
  const fontClashRegular = new FontFace(
    'Clash Display Variable',
    `url(${require('../assets/fonts/ClashDisplay-Regular.otf')})`,
    { weight: '400' },
  );

  const fontClashMedium = new FontFace(
    'Clash Display Variable',
    `url(${require('../assets/fonts/ClashDisplay-Medium.otf')})`,
    { weight: '500' },
  );

  const fontClashSemibold = new FontFace(
    'Clash Display Variable',
    `url(${require('../assets/fonts/ClashDisplay-Semibold.otf')})`,
    { weight: '600' },
  );

  const fontClashBold = new FontFace(
    'Clash Display Variable',
    `url(${require('../assets/fonts/ClashDisplay-Bold.otf')})`,
    { weight: '700' },
  );

  try {
    await Promise.all([
      fontClashRegular.load(),
      fontClashMedium.load(),
      fontClashSemibold.load(),
      fontClashBold.load(),
    ]);
    console.log('All fonts preloaded');
  } catch (error) {
    console.error('Error preloading fonts:', error);
  }

  document.fonts.add(fontClashRegular);
  document.fonts.add(fontClashMedium);
  document.fonts.add(fontClashSemibold);
  document.fonts.add(fontClashBold);
};
