import { clearTasks } from 'api/tasks';
import User from 'components/User';
import { EModals } from 'interface/modals';
import React from 'react';
import useBalanceStore from 'store/balance.store';
import useMainStore from 'store/main.store';
import useModalStore from 'store/modals.store';
import useWeb3Store from 'store/web3.store';
import { formatNumber } from 'utils/formatNumber';
import useTasksStore from 'store/tasks.store';
import { ETaskSlug, ETaskStatus } from 'interface/task';

import './Header.scss';

const Header: React.FC = () => {
  const nearWallet = useWeb3Store((state) => state.nearWallet);
  const showModal = useModalStore((state) => state.showModal);
  const user = useMainStore((state) => state.user);
  const tickets = useBalanceStore((state) => state.tickets);
  const hapiScoreTaskStatus = useTasksStore(
    (state) => state.partnersTasks.get(ETaskSlug.PARTNER_HAPI_SCORE)?.status,
  );

  const username = user?.username;
  const photoUrl = user?.photoUrl;

  const onFAQClick = () => {
    showModal(EModals.FAQ);
  };

  const onWalletClick = () => {
    showModal(EModals.DISCONNECT_WALLET, {});
  };

  return (
    <div className="header">
      <User
        photoUrl={photoUrl || ''}
        username={username || ''}
        isShowWallet
        wallet={
          user?.hotNearWalletBonusClaimed && user?.hotNearWalletAddress
            ? user?.hotNearWalletAddress
            : nearWallet?.accountId
        }
        onWalletClick={onWalletClick}
        hapiScore={user?.hapiScore}
        isShowHapiScore={hapiScoreTaskStatus === ETaskStatus.COMPLETED_CLAIMED}
      />
      <div className="user-tickets" onClick={clearTasks}>
        <img src="images/ticket.svg" alt="Tickets" />
        <span>{formatNumber(tickets, 'short')}</span>
      </div>
      <button className="help-button" onClick={onFAQClick}>
        <img src="images/help.png" alt="?" />
      </button>
    </div>
  );
};

export default Header;
