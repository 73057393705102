import React, { useRef, useState } from 'react';
import useSWRInfinite from 'swr/infinite';
import useSWR from 'swr';
import { motion } from 'framer-motion';
import User from 'components/User';
import useMainStore from 'store/main.store';
import { fetchLeaders, fetchUserPosition } from 'api/stats';
import Button from 'components/Button';
import { formatNumber } from 'utils/formatNumber';

import './Stats.scss';

const LEADERS_LIMIT = 150;
const ITEMS_PER_PAGE = 10;

const getPosition = (i: number) =>
  i <= 2 ? (
    <img src={`images/leader-${i + 1}.png`} alt={i.toString()} />
  ) : (
    <span className="leader-position">{i + 1}</span>
  );

const getKey = (pageIndex: number, previousPageData: any[]) => {
  if (previousPageData && !previousPageData.length) return null;
  return `/leaders?page=${pageIndex + 1}&limit=${ITEMS_PER_PAGE}`;
};

const Stats: React.FC = () => {
  const pageRef = useRef<HTMLDivElement | null>(null);
  const user = useMainStore((state) => state.user);
  const [totalUsers, setTotalUsers] = useState(0);

  const { data, error, setSize, isValidating } = useSWRInfinite(
    (pageIndex, previousPageData) => getKey(pageIndex, previousPageData),
    async (url) => {
      const { leaders, totalRecords } = await fetchLeaders(
        parseInt(url.split('page=')[1].split('&')[0]),
        ITEMS_PER_PAGE,
      );
      setTotalUsers(totalRecords);
      return leaders;
    },
    { revalidateOnFocus: false, revalidateOnMount: true },
  );

  const { data: userPosition, isLoading: isLoadingPosition } = useSWR(
    '/user-rank',
    () => fetchUserPosition(),
    { revalidateOnFocus: false, revalidateOnMount: true },
  );

  const leaders = data ? data.flat() : [];
  const isLoading = isValidating || (!data && !error);
  const hasMore = data && data[data.length - 1]?.length === ITEMS_PER_PAGE;

  const canLoadMore = leaders.length < LEADERS_LIMIT && hasMore;

  const leadersRating = leaders.slice(0, LEADERS_LIMIT);

  return (
    <motion.div
      className="stats-page"
      ref={pageRef}
      initial={{ opacity: 0 }}
      exit={{ opacity: 0 }}
      animate={{ opacity: 1 }}>
      <div className="stats-page-header">
        <h3>Leaderboard</h3>
        {/* <p>Your ranking is based on your league and the number of airdrop passes</p> */}
      </div>
      <div className="current-user">
        <User photoUrl={user?.photoUrl || ''} username={user?.username || ''} />
        <p className="place">
          <b>#{isLoadingPosition ? '9999' : userPosition}</b>
          <span>Your place</span>
        </p>
      </div>
      <div className="stats-total-users">
        <p>
          Total users: <span>{formatNumber(totalUsers)}</span>
        </p>
      </div>
      <ul>
        {leadersRating.map((leader, i) => (
          <li key={i}>
            <User
              photoUrl={leader.photoUrl}
              username={leader.username}
              isShowTickets
              ticketsAmount={leader.coins}
            />
            <span className="leader-position">{getPosition(i)}</span>
          </li>
        ))}
        {isLoading && (
          <li className="list-loading">
            <div className="loading-circle">
              <div className="loading-inner" />
            </div>
          </li>
        )}
        {!leadersRating.length && !isLoading && (
          <p className="no-items">
            {!leadersRating.length ? 'No leaders yet.' : 'No more leaders.'}
          </p>
        )}
      </ul>
      {canLoadMore && !isLoading && (
        <Button
          color="white"
          className="load-more-btn"
          text="Load more"
          onClick={() => setSize((prevSize) => prevSize + 1)}
        />
      )}
    </motion.div>
  );
};

export default Stats;
