import React, { useEffect } from 'react';
import { motion } from 'framer-motion';
import { formatNumber } from 'utils/formatNumber';
import TasksList from 'components/Tasks';
import { getTaskIcon, HAPI_SCORE_URL } from 'constants/tasks';
import { ETaskSlug, ETaskStatus, ITaskProgress } from 'interface/task';
import useBalanceStore from 'store/balance.store';
import { verifyTasks, startBasicTask, submitHapiScoreTask, verifyFittonTask } from 'api/tasks';
import { sleep } from 'utils/time';
import useMainStore from 'store/main.store';
import useTasksStore from 'store/tasks.store';
import {
  getGeneralTasksProgress,
  getGeneralTasksRender,
  getPartnersTasksProgress,
  getPartnersTasksRender,
  isAllGeneralTasksCompleted,
} from 'store/tasks.selector';

import './Tasks.scss';
import useModalStore from 'store/modals.store';
import { EModals } from 'interface/modals';
import classNames from 'classnames';
import { showErrorToast } from 'utils/toastUtils';
import { showSuccessToast } from 'utils/toastUtils';

const Tasks: React.FC = () => {
  const { showModal } = useModalStore();
  const tg = useMainStore((state) => state.tgWebApp);
  const coins = useBalanceStore((state) => state.coins);
  const tickets = useBalanceStore((state) => state.tickets);
  const updateCoins = useBalanceStore((state) => state.updateCoins);
  const addCoins = useBalanceStore((state) => state.addCoins);
  const addTickets = useBalanceStore((state) => state.addTickets);
  const partnersTasksRender = useTasksStore(getPartnersTasksRender);
  const generalTasksRender = useTasksStore(getGeneralTasksRender);
  const generalTasksProgress = useTasksStore(getGeneralTasksProgress);
  const partnersTasksProgress = useTasksStore(getPartnersTasksProgress);
  const isGeneralCompleted = useTasksStore(isAllGeneralTasksCompleted);
  const updateGeneralTaskStatus = useTasksStore(
    ({ updateGeneralTaskStatus }) => updateGeneralTaskStatus,
  );
  const updatePartnerTaskStatus = useTasksStore(
    ({ updatePartnerTaskStatus }) => updatePartnerTaskStatus,
  );
  const updateTask = useTasksStore(({ updateTask }) => updateTask);

  // TODO: Make hook
  const onGeneralTaskClick = async (task: ITaskProgress) => {
    if (tg?.openLink) {
      if (
        task.template.slug === ETaskSlug.BASIC_TG ||
        task.template.slug === ETaskSlug.BASIC_CHAT
      ) {
        tg.openTelegramLink(task.template.url);
      } else {
        tg.openLink(task.template.url);
      }
    } else {
      window.open(task.template.url, '_blank', 'noopener noreferrer');
    }
    await startBasicTask(task.id);
    updateGeneralTaskStatus(task.id, ETaskStatus.VERIFY_REQUESTED);
    await sleep(5000);
    const resp = await verifyTasks();
    if (resp) {
      updateCoins(resp.coins);
      resp.tasks.forEach((task) => updateGeneralTaskStatus(task.id, task.status));
    }
  };

  useEffect(() => {
    const checkAndVerifyTasks = async () => {
      const hasPendingTasks = generalTasksRender.some(
        (task) => task.status === ETaskStatus.VERIFY_REQUESTED,
      );
      if (hasPendingTasks) {
        const resp = await verifyTasks();
        if (resp) {
          resp.coins && updateCoins(resp.coins);
          resp.tasks.forEach((task) => updateGeneralTaskStatus(task.id, task.status));
        }
      }
    };

    checkAndVerifyTasks();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [generalTasksProgress]);

  const onHapiScoreTaskClick = async (task: ITaskProgress) => {
    const res = await submitHapiScoreTask();
    if (!res?.status) {
      if (tg?.openTelegramLink) {
        tg.openTelegramLink(HAPI_SCORE_URL);
      } else {
        window.open(HAPI_SCORE_URL, '_blank', 'noopener noreferrer');
      }
      return;
    }
    updatePartnerTaskStatus(task.template.slug, res.status);
    if (
      res.status === ETaskStatus.COMPLETED_CLAIMED ||
      res.status === ETaskStatus.COMPLETED_NOT_CLAIMED
    ) {
      addCoins(task.template.rewardCoins);
      addTickets(task.template.rewardTickets);
    }
  };

  const onFittonTaskClick = async (task: ITaskProgress) => {
    showModal(EModals.FITTON_CONNECT, {
      onSubmit: async () => {
        const resp = await verifyFittonTask();
        if (resp) {
          updatePartnerTaskStatus(task.template.slug, resp.status);
          updateTask(task.template.slug, { startedAt: resp.startedAt });
          if (
            resp.status === ETaskStatus.COMPLETED_CLAIMED ||
            resp.status === ETaskStatus.COMPLETED_NOT_CLAIMED
          ) {
          addCoins(task.template.rewardCoins);
          addTickets(task.template.rewardTickets);
          showSuccessToast('Task successfully verified!.');
        } else {
          showErrorToast('Cannot verify task, please try again!');
        }
        }
      },
    });
  };

  const onTaskClick = (task: ITaskProgress) => {
    if (task.template.slug === ETaskSlug.PARTNER_HAPI_SCORE) {
      onHapiScoreTaskClick(task);
    } else if (task.template.slug === ETaskSlug.PARTNER_FITTON) {
      onFittonTaskClick(task);
    } else {
      throw new Error('Unknown task');
    }
  };

  return (
    <motion.div
      className="tasks-page"
      initial={{ opacity: 0 }}
      exit={{ opacity: 0 }}
      animate={{ opacity: 1 }}>
      <div className="tasks-page-header">
        <h4>Your balance</h4>
        <div className="tasks-page-header__balance">
          <p>
            {formatNumber(coins)}
            <img className="point" src="images/point.png" alt="Points" />
          </p>
          <p>
            {formatNumber(tickets)}
            <img className="ticket" src="images/ticket.svg" alt="Tickets" />
          </p>
        </div>
      </div>
      <div className="tasks-page-body">
        <TasksList label="General" completed={generalTasksProgress} className="general-tasks-list">
          {generalTasksRender.map((task, i) => (
            <li
              className={task.status ? task.status : ''}
              key={i}
              onClick={() => onGeneralTaskClick(task)}>
              <img src={task.template.image} alt="" />
              <div className="task-info">
                <p className="task">{task.template.title}</p>
                <p className="reward">
                  <img src="images/point.png" alt="Tickets" />
                  <span>+{task.template.rewardCoins}</span>
                </p>
              </div>
              <p className="status-icon">
                <img src={getTaskIcon(task.status)} alt="" />
              </p>
            </li>
          ))}
        </TasksList>
        <TasksList
          label="Specials"
          completed={partnersTasksProgress}
          isDisabled={!isGeneralCompleted}
          className="special-tasks-list">
          {partnersTasksRender.map((task, i) => (
            <li
              className={classNames(task.status ? task.status : '', task.template.slug)}
              key={i}
              onClick={() => onTaskClick(task)}>
              <img src={task.template.image} alt="" />
              <div className="task-info">
                <p className="task">{task.template.title}</p>
                <p className="reward">
                  {!!task.template.rewardTickets && (
                    <span className="reward-item reward-item--tickets">
                      <img src="images/ticket.svg" alt="Tickets" />
                      <span>{task.template.rewardTickets} Airdrop Pass</span>
                    </span>
                  )}
                  {!!task.template.rewardCoins && (
                    <span className="reward-item reward-item--coins">
                      <img src="images/point.png" alt="Tickets" />
                      <span>+{formatNumber(task.template.rewardCoins)}</span>
                    </span>
                  )}
                </p>
              </div>
              {task.status && (
                <p className="status-icon">
                  <img src={getTaskIcon(task.status)} alt="" />
                </p>
              )}
            </li>
          ))}
        </TasksList>
      </div>
    </motion.div>
  );
};

export default Tasks;
