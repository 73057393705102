import { toast } from 'react-toastify';

const defaultToastOptions = {
  autoClose: 3000,
};

const createToastOptions = (className: string, bodyClassName: string, icon: JSX.Element) => ({
  ...defaultToastOptions,
  className,
  bodyClassName,
  icon,
});

const defaultSuccessOptions = createToastOptions(
  'success-toast',
  'success-toast-body',
  <img src="images/toast-success-icon.png" alt="" />,
);

const defaultWarningOptions = createToastOptions('warning-toast', 'warning-toast-body', <></>);

const defaultErrorOptions = createToastOptions(
  'error-toast',
  'error-toast-body',
  <img src="images/toast-error-icon.png" alt="" />,
);

const showToast = (
  type: 'success' | 'warning' | 'error',
  message: string,
  options: object = {},
) => {
  const toastOptions = {
    success: defaultSuccessOptions,
    warning: defaultWarningOptions,
    error: defaultErrorOptions,
  }[type];

  toast[type](message, { ...toastOptions, ...options });
};

export const showSuccessToast = (message: string, options: object = {}) =>
  showToast('success', message, options);
export const showWarningToast = (message: string, options: object = {}) =>
  showToast('warning', message, options);
export const showErrorToast = (message: string, options: object = {}) =>
  showToast('error', message, options);
