import { LEVELS } from 'constants/levels';
import classNames from 'classnames';
import useBalanceStore from 'store/balance.store';
import { useNavigate } from 'react-router-dom';
import useMainStore from 'store/main.store';

import './ProgressBar.scss';

interface IProgressBarProps {}

const ProgressBar: React.FC<IProgressBarProps> = () => {
  const navigate = useNavigate();
  const level = useMainStore((state) => state?.user?.level);
  const levelDetails = LEVELS[level as keyof typeof LEVELS];
  const nextLevelDetails = LEVELS[((level || 0) + 1) as keyof typeof LEVELS];
  const coins = useBalanceStore((state) => state.coins);
  const nextLevelCoins = nextLevelDetails.coins;
  const levelProgress = nextLevelCoins === 0 ? 100 : (coins / nextLevelCoins) * 100;

  return (
    <div className="progress-bar-container" onClick={() => navigate('/levels')}>
      <img
        className="level-image"
        src={levelDetails.levelImage}
        alt={levelDetails.level.toString()}
      />
      <span className="level-name">{levelDetails.name}</span>
      <div className={classNames('progress-bar')}>
        <div
          style={{
            width: `${levelProgress >= 100 ? 100 : levelProgress}%`,
            background: levelDetails.color,
          }}
          className="progress-bar__inner"
        />
      </div>
      <img className="arrow-image" src="images/arrow.svg" alt=">" />
    </div>
  );
};

export default ProgressBar;
