import { create } from 'zustand';

interface IBalanceStore {
  referralRewards: number;
  coins: number;
  tickets: number;
  updateReferralRewards: (referralRewards: number) => void;
  updateCoins: (coins: number) => void;
  updateTickets: (tickets: number) => void;
  addCoins: (amount: number) => void;
  addTickets: (amount: number) => void;
}

const useBalanceStore = create<IBalanceStore>((set) => ({
  referralRewards: 0,
  coins: 0,
  tickets: 0,

  updateReferralRewards: (referralRewards: number) => set(() => ({ referralRewards })),
  updateCoins: (coins: number) => set(() => ({ coins })),
  updateTickets: (tickets: number) => set(() => ({ tickets })),
  addCoins: (amount: number) => set((state) => ({ coins: state.coins + amount })),
  addTickets: (amount: number) => set((state) => ({ tickets: state.tickets + amount })),
}));

export default useBalanceStore;
