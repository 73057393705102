import { IFarming, INFTStaking } from 'interface/farming';
import { create } from 'zustand';

interface IFarmingStore {
  farming: IFarming | null;
  staking: INFTStaking | null;
  speedBoostLevel: number;
  timeBoostLevel: number;
  isLoading: boolean;
  setFarming: (farming: IFarming | null) => void;
  setIsLoading: (isLoading: boolean) => void;
  setStaking: (staking: INFTStaking | null) => void;
  setSpeedBoostLevel: (speedBoostLevel: number) => void;
  setTimeBoostLevel: (timeBoostLevel: number) => void;
}

const useFarmingStore = create<IFarmingStore>((set) => ({
  farming: null,
  staking: null,
  speedBoostLevel: 1,
  timeBoostLevel: 1,
  isLoading: true,
  isStakingLoading: true,
  setFarming: (farming: IFarming | null) => set(() => ({ farming })),
  setStaking: (staking: INFTStaking | null) => set(() => ({ staking })),
  setIsLoading: (isLoading: boolean) => set(() => ({ isLoading })),
  setSpeedBoostLevel: (speedBoostLevel: number) => set(() => ({ speedBoostLevel })),
  setTimeBoostLevel: (timeBoostLevel: number) => set(() => ({ timeBoostLevel })),
}));

export default useFarmingStore;
