import { botAppUrl } from 'config';

export const REFERRAL_BONUS = 150;
export const REFERRAL_BONUS_PERCENT = 0.2;

export const MAX_DAILY_STREAK = 30;

export const DAILY_TASK_PRICE = 750;
export const DAILY_TASK_REWARD = 5;

export const HOT_WALLET_CONNECT_BONUS = 500;

export const BOT_LINK = botAppUrl;

export const TEMPLATE_VIDEO =
  'https://ipfs.pitchtalk.com/ipfs/bafybeibb2fhmdxhzldxeforwiwd2snf3rdbje76bbibg6vtfp5jr6xeskm';
