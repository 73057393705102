import { IGift } from 'interface/gift';
import api from './APIService';
import { handleApiError } from './errorHandlerService';

export const getGifts = async (status: 'claimed' | 'unclaimed' = 'unclaimed'): Promise<IGift[]> => {
  try {
    const response = await api.get<IGift[]>(`users/gifts?status=${status}`);
    return response.data;
  } catch (error: any) {
    handleApiError(error);
    return [];
  }
};

export const createGift = async ({
  username,
  coins,
  tickets,
  description,
}: {
  username: string;
  coins: number;
  tickets: number;
  description: string;
}): Promise<IGift | null> => {
  try {
    const response = await api.post<IGift>('users/create-gift', {
      username,
      coins,
      tickets,
      description,
    });
    return response.data;
  } catch (error: any) {
    handleApiError(error);
    return null;
  }
};

export const claimGift = async (giftId: string): Promise<IGift | null> => {
  try {
    const response = await api.get<IGift>(`users/claim-gift/${giftId}`);
    return response.data;
  } catch (error: any) {
    handleApiError(error);
    return null;
  }
};
