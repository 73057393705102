import axios from 'axios';
import {
  NFT_LEVEL_TRAIT_TYPE,
  NFT_REWARDS_DD_LEVEL_TRAIT,
  NFT_REWARDS_USMEN_LEVEL_TRAIT,
  NFTS_ACTIVE,
} from 'constants/nft';
import {
  INFTLevelsResponse,
  ENFT_GRADE,
  INFTToken,
  ENFT_TYPE,
  ENFT_USMEN_GRADE,
  ENFT_DD_GRADE,
  ENFT_YUP_GRADE,
} from 'interface/nft';
import NearService from 'services/NearService';
import { handleApiError } from './errorHandlerService';
import api from './APIService';

const ipfsHostStart = 'https://ipfs.pitchtalk.com/ipfs/';
const ipfsHostEnd = '';

export const getCorrectIPFSLink = (link: string) => {
  const regexPattern = /^https?:\/\/([a-z0-9]+)\.ipfs\.nftstorage\.link(\/.*)?$/i;
  const match = link.match(regexPattern);

  if (match && match[1]) {
    const newPath = match[2] || '';
    return `${ipfsHostStart}${match[1]}${newPath}${ipfsHostEnd}`;
  }

  return link;
};

export const getUserNFTs = async (
  accountId: string,
): Promise<{
  pt: INFTToken[];
  here: INFTToken[];
  hapi: INFTToken[];
  usmen: INFTToken[];
  dd: INFTToken[];
  yup: INFTToken[];
}> => {
  try {
    const ptNfts = await NearService.getUserWeb3NFTs(accountId, ENFT_TYPE.PITCHTALK);
    const hereNfts = await NearService.getUserWeb3NFTs(accountId, ENFT_TYPE.HOT);
    const hapiNfts = await NearService.getUserWeb3NFTs(accountId, ENFT_TYPE.HAPI);
    const usmenNfts = await NearService.getUserWeb3NFTs(accountId, ENFT_TYPE.USMEN);
    const ddNfts = await NearService.getUserWeb3NFTs(accountId, ENFT_TYPE.DD);
    const yupNfts = await NearService.getUserWeb3NFTs(accountId, ENFT_TYPE.YUP);

    return {
      pt: ptNfts,
      here: hereNfts,
      hapi: hapiNfts,
      usmen: usmenNfts,
      dd: ddNfts,
      yup: yupNfts,
    };
  } catch (error) {
    return { pt: [], here: [], hapi: [], usmen: [], dd: [], yup: [] };
  }
};

// NFT GRADE
export const getNFTGrade = async (nft: string): Promise<ENFT_GRADE> => {
  try {
    const response = await axios.get<INFTLevelsResponse>(nft);
    const attributes = response.data.attributes;
    const grade = attributes.find((attr) => attr.trait_type === NFT_LEVEL_TRAIT_TYPE)?.value;
    return grade as ENFT_GRADE;
  } catch (error) {
    return ENFT_GRADE.Noob;
  }
};

export const getUsmenNFTGrade = async (nft: string): Promise<ENFT_USMEN_GRADE> => {
  try {
    const link = getCorrectIPFSLink(nft);
    const response = await axios.get<INFTLevelsResponse>(link);
    const attributes = response.data.attributes;
    const grade = attributes.find(
      (attr) => attr.trait_type === NFT_REWARDS_USMEN_LEVEL_TRAIT,
    )?.value;

    if (!grade) {
      throw new Error('Unable to get USMEN NFT grade');
    } else if (grade.includes('Gold')) {
      return ENFT_USMEN_GRADE.Rare;
    } else return ENFT_USMEN_GRADE.Common;
  } catch (error) {
    throw error;
  }
};

export const getDDNFTGrade = async (nft: string): Promise<ENFT_DD_GRADE> => {
  try {
    const link = getCorrectIPFSLink(nft);
    const response = await axios.get<INFTLevelsResponse>(link);
    const attributes = response.data.attributes;
    const grade = attributes.find((attr) => attr.trait_type === NFT_REWARDS_DD_LEVEL_TRAIT)?.value;

    if (!grade) {
      throw new Error('Unable to get DD NFT grade');
    } else if (grade.includes('Both')) {
      return ENFT_DD_GRADE.Rare;
    }
    return ENFT_DD_GRADE.Regular;
  } catch (error) {
    throw error;
  }
};

const titleBlacklist = [
  'egg',
  'passport',
  'Yupik - Chiter',
  'Chest',
  'Press',
  'тест',
  'boost',
  'mystical',
];

export const getYupNFTGrade = (nft: INFTToken): ENFT_YUP_GRADE => {
  try {
    const title = nft.metadata.title?.toLowerCase() || '';

    const gradesInOrder = [
      ENFT_YUP_GRADE.Common,
      ENFT_YUP_GRADE.Uncommon,
      ENFT_YUP_GRADE.Rare,
      ENFT_YUP_GRADE.Epic,
      ENFT_YUP_GRADE.Legendary,
      ENFT_YUP_GRADE.Four,
      ENFT_YUP_GRADE.Three,
      ENFT_YUP_GRADE.Two,
      ENFT_YUP_GRADE.One,
      ENFT_YUP_GRADE.Zero,
    ];

    for (const grade of gradesInOrder) {
      if (title.includes(grade.toLowerCase())) {
        return grade;
      }
    }

    if (titleBlacklist.some((blacklisted) => title.includes(blacklisted.toLowerCase()))) {
      return ENFT_YUP_GRADE.Common;
    }

    throw new Error('Unable to get Yup NFT grade');
  } catch (error) {
    throw error;
  }
};

// GRADES
export const getUserNFTsGrades = async (nfts: INFTToken[]): Promise<Map<string, ENFT_GRADE>> => {
  try {
    const grades = new Map<string, ENFT_GRADE>();
    const gradePromises = nfts.map(async (nft) => {
      const grade = await getNFTGrade(nft.metadata.extra || '');
      grades.set(nft.token_id, grade);
    });
    await Promise.all(gradePromises);
    return grades;
  } catch (error) {
    return new Map<string, ENFT_GRADE>();
  }
};

export const getUserNFTsUsmenGrades = async (
  nfts: INFTToken[],
): Promise<Map<string, ENFT_USMEN_GRADE>> => {
  try {
    const grades = new Map<string, ENFT_USMEN_GRADE>();
    const gradePromises = nfts.map(async (nft) => {
      const grade = await getUsmenNFTGrade(nft.metadata.extra || '');
      grades.set(nft.token_id, grade);
    });
    await Promise.all(gradePromises);
    return grades;
  } catch (error) {
    return new Map<string, ENFT_USMEN_GRADE>();
  }
};

export const getUserNFTsDDGrades = async (
  nfts: INFTToken[],
): Promise<Map<string, ENFT_DD_GRADE>> => {
  try {
    const grades = new Map<string, ENFT_DD_GRADE>();
    const gradePromises = nfts.map(async (nft) => {
      const grade = await getDDNFTGrade(nft.metadata.extra || '');
      grades.set(nft.token_id, grade);
    });
    await Promise.all(gradePromises);
    return grades;
  } catch (error) {
    return new Map<string, ENFT_DD_GRADE>();
  }
};

export const getUserNFTsYupGrades = (nfts: INFTToken[]): Map<string, ENFT_YUP_GRADE> => {
  try {
    const grades = new Map<string, ENFT_YUP_GRADE>();
    nfts.forEach((nft) => {
      const grade = getYupNFTGrade(nft);
      grades.set(nft.token_id, grade);
    });
    return grades;
  } catch (error) {
    return new Map<string, ENFT_YUP_GRADE>();
  }
};

// BONUSES
export const getUserNFTsBonuses = async (
  nfts: string[],
  contract: ENFT_TYPE,
): Promise<Map<string, boolean>> => {
  if (!NFTS_ACTIVE[contract]) {
    return new Map<string, boolean>();
  }
  if (!nfts.length) {
    return new Map<string, boolean>();
  }
  try {
    const response = await api.get<{ [nftId: string]: boolean }>(
      `/tasks/nft-holder-bonus?nfts=${nfts.join(',')}&contract=${contract}`,
    );
    return new Map<string, boolean>(Object.entries(response.data || {}));
  } catch (error) {
    return new Map<string, boolean>();
  }
};

export const claimNFTBonus = async (
  nftId: string,
  contract: ENFT_TYPE,
): Promise<{ rewards: { coins: number; tickets: number } } | boolean> => {
  try {
    const response = await api.post('/tasks/nft-holder-bonus', { nftId, contract });
    return response.data;
  } catch (error) {
    handleApiError(error);
    return false;
  }
};

export const checkNFTBonus = async (
  nftId: string,
  contract: ENFT_TYPE,
): Promise<{ isClaimed: boolean } | null> => {
  if (!NFTS_ACTIVE[contract]) {
    return { isClaimed: false };
  }
  if (!nftId) {
    return { isClaimed: false };
  }
  try {
    const response = await api.get<{ [nftId: string]: boolean }>(
      `/tasks/nft-holder-bonus?nfts=${nftId}&contract=${contract}`,
    );
    return { isClaimed: response.data[nftId] };
  } catch (error) {
    handleApiError(error);
    return { isClaimed: false };
  }
};
